import React, { ReactNode } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

const BaseStyle = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: white;
    height: 100%;
  }


  body {
    margin: 0;
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

` as any

export class ErrorBoundary extends React.Component<{ children: ReactNode }> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(exception: any) {
    // TODO: You can also log the error to an error reporting service
    // appInsightsTracker.trackException({
    //   type: TrackEventType.Exception,
    //   exception,
    //   severity: SeverityLevel.Error,
    // })
  }

  render() {
    const state = this.state as { hasError: boolean; errorLabel: string }

    const renderError = () => (
      <Wrapper>
        <BaseStyle />
        <div>Error as occurred</div>
      </Wrapper>
    )

    return state.hasError ? renderError() : <>{this.props.children}</>
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.03);
`
