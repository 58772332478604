import { configureApiSaga } from 'for-util/lib/redux/frr/rest.saga'
import { AuthSaga } from './auth.saga'
import 'react-toastify/dist/ReactToastify.css'
import { fork } from 'redux-saga/effects'

const ApiSaga = configureApiSaga({
  baseUrl: `${process.env.REACT_APP_APP_SERVER}/api`,
  getToken: () => {
    console.log(localStorage.getItem('auth_token'))
    return Promise.resolve(localStorage.getItem('auth_token') || '')
  },
  tokenKey: 'x-access-token',
  debug: false,
  timeout: 20000,
})

export function* RootSaga() {
  yield fork(ApiSaga)
  yield fork(AuthSaga)
}
