import { Modal as MaterialUiModal } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { Stylesheet, useStylesheet } from '../../stylesheet/stylesheet'
import { createStyled, useCSSStyles } from '../../stylesheet/util'

const ModalInnerWrapper = createStyled('div')

type Props = {
  children: ReactNode
  onClose?: () => void
  open: boolean
  style?: Partial<Stylesheet['modal']>
}

export const Modal = (props: Props) => {
  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'modal')(props.style)

  return (
    <MaterialUiModal
      open={props.open}
      onClose={props.onClose}
      style={getCSSStyle('outerWrapper').computedStyle}
    >
      <ModalInnerWrapper {...getCSSStyle('innerWrapper')}>
        {props.open ? props.children : <></>}
      </ModalInnerWrapper>
    </MaterialUiModal>
  )
}
