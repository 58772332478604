import { toast } from 'react-toastify'
import { put, takeEvery } from 'redux-saga/effects'
import {
  me,
  login,
  noToken,
  ViewActionType,
  setAuthUser,
  register,
} from '../actions/auth.actions'

export function* AuthSaga() {
  yield takeEvery(
    [login.types.success],
    function* (action: typeof login.action.success) {
      yield localStorage.setItem('auth_token', action.payload.token)
      yield put(setAuthUser(action.payload.user))
    },
  )

  yield takeEvery(
    [register.types.success],
    function* (action: typeof register.action.success) {
      yield toast.success('Registration successful')
      action.meta.navigate('/login')
    },
  )

  yield takeEvery([login.types.failure], function* (action: any) {
    yield toast.error('Login failed')
  })

  yield takeEvery(
    [me.types.success],
    function* (action: typeof me.action.success) {
      yield put(setAuthUser(action.payload))
    },
  )

  yield takeEvery([me.types.failure], function* () {
    yield put(noToken())
    yield localStorage.removeItem('auth_token')
  })

  yield takeEvery([ViewActionType.Logout], function* () {
    yield localStorage.removeItem('auth_token')
    window.location.reload()
  })
}
