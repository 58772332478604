import { ReactNode } from 'react'
import { IconBaseProps } from 'react-icons'
import { CSSProperties } from 'styled-components'
import { Div } from '../../html'
import { Stylesheet, useStylesheet } from '../../stylesheet/stylesheet'
import { useCSSStyles } from '../../stylesheet/util'

export enum IconButtonType {
  Stroke = 'Stroke',
  Box = 'Box',
}

const mapTypeToStyleIconKey: {
  [k in IconButtonType]: keyof Partial<Stylesheet['iconButton']>
} = {
  [IconButtonType.Stroke]: 'icon',
  [IconButtonType.Box]: 'boxIcon',
}

const mapTypeToStyleActiveKey: {
  [k in IconButtonType]: keyof Partial<Stylesheet['iconButton']>
} = {
  [IconButtonType.Stroke]: 'iconActive',
  [IconButtonType.Box]: 'boxIconActive',
}

export type Props = {
  disabled?: boolean
  active?: boolean
  className?: string
  onClick?: (e: any) => void
  icon: (props: IconBaseProps) => ReactNode
  override?: CSSProperties // normal styled components styling
  style?: Partial<Stylesheet['iconButton']> // styling must follow strict stylesheet pattern
  type?: IconButtonType
}

export const IconButton = (props: Props) => {
  const type = props.type || IconButtonType.Stroke
  const stylesheet = useStylesheet()

  const getCSSStyle = useCSSStyles(stylesheet, 'iconButton')(props.style)
  return (
    <Div
      className={props.className}
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...getCSSStyle({
        box: props.type === IconButtonType.Box,
        boxActive: props.active,
      })}
      onClick={!props.disabled ? props.onClick : undefined}
    >
      {props.icon({
        style: getCSSStyle(
          {
            icon: true,
            [mapTypeToStyleIconKey[type]]: true,
            [mapTypeToStyleActiveKey[type]]: props.active,
          },
          props.override,
        ).computedStyle,
      })}
    </Div>
  )
}
