import { ReactNode } from 'react'
import { IconBaseProps } from 'react-icons'
import { Div } from '../../html'
import { Stylesheet, useStylesheet } from '../../stylesheet/stylesheet'
import { useCSSStyles } from '../../stylesheet/util'

export type Props = {
  onChange?: (value: string) => void
  options: Array<{
    icon: (iconProps: IconBaseProps) => ReactNode
    text: string
    key: string
  }> //select from icon components
  style?: Partial<Stylesheet['selectIcon']>
  required?: boolean | ((formData: any) => boolean)
  value: string | null | undefined
}

export const SelectIcon = (props: Props) => {
  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'selectIcon')(props.style)

  return (
    <Div
      {...getCSSStyle({
        wrapper: true,
      })}
    >
      {props.options.map((option, idx) => (
        <Div
          {...getCSSStyle({
            iconWrapper: true,
            selectedIconWrapper: props.value === option.key,
          })}
          key={idx}
          onClick={() => props.onChange?.(option.key)}
        >
          {option.icon({})}
        </Div>
      ))}
    </Div>
  )
}
