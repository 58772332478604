import styled, { css, CSSProperties } from 'styled-components'
import { Stylesheet } from './stylesheet'

export const omitKeys = <T extends Record<string, unknown>>(
  obj: T,
  keysIn: Array<keyof T>,
) =>
  Object.keys(obj).reduce(
    (o: T, k: string) =>
      keysIn.includes(k as keyof T) ? o : { ...o, [k]: obj[k] },
    {} as T,
  )

export const dynamicStyleKeys = [
  ':nth-child(odd)',
  ':nth-child(even)',
  ':hover',
  ':focus',
  ':active',
  ':invalid',
  ':readonly',
  '::selection',
  ':disabled',
  '@media-mobile',
]

const animationKeys = ['@animation']

const nonPixelKeys = [
  'flexGrow',
  'flexShrink',
  'fontWeight',
  'lineHeight',
  'opacity',
  'zIndex',
]

export const mapStylesToCSS = (style: any) =>
  Object.entries(style)
    .map(
      ([cssKey, cssValue]) =>
        `${cssKey.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)}: ${
          isNaN(cssValue as any) || nonPixelKeys.includes(cssKey)
            ? cssValue
            : `${cssValue}px`
        };`,
    )
    .join(' ')

/*
 * Creates a styled component with support for pseudo elements and media query styles provided from a style object
 */

export const createStyled = (type: any) => (styled as any)[type].attrs(
  // eslint-disable-next-line no-empty-pattern
  ({}) => ({}),
)`
  ${(props: { cssStyles: string }) =>
    css`
      ${props.cssStyles}
    `}
`

/*
 * Generates the CSS style object for a styled component created with the createStyled() function
 */

export const useCSSStyles =
  <C extends keyof Stylesheet>(theme: Stylesheet, componentKey: C) =>
  (override?: Partial<Stylesheet[C]>) =>
  <K extends keyof Stylesheet[C]>(
    elementKeys:
      | Array<K>
      | K
      | Partial<{ [k in keyof Stylesheet[C]]: boolean }>,
    internalOverride?: CSSProperties,
    className?: string,
  ): { computedStyle: CSSProperties; cssStyles: string } => {
    let keys: Array<K> = []

    if (Array.isArray(elementKeys)) {
      keys = elementKeys
    } else if (typeof elementKeys === 'string') {
      keys = [elementKeys]
    } else {
      keys = Object.keys(elementKeys).filter(
        (k) => (elementKeys as any)[k],
      ) as Array<K>
    }
    //only includes keys which have a property
    // common: {} --> not included
    // common: {color: 'red'} --> included

    const styles = {
      css: omitKeys(
        {
          ...(keys.reduce(
            (obj, elementKey) => ({
              ...obj,
              ...theme[componentKey][elementKey], // include theme for every element inside the component
              ...(override && override[elementKey] ? override[elementKey] : {}), // overwrite default styles
            }),
            {},
          ) as any),
          ...(internalOverride || {}), // overwrite styles
        },
        dynamicStyleKeys.concat(animationKeys) as any,
      ),
      ...dynamicStyleKeys.reduce((obj, k) => {
        return {
          ...obj,
          [k]: keys.reduce((obj, elementKey) => {
            return {
              ...obj,
              ...((theme as any)[componentKey][elementKey][k] || {}),
              ...(override && override[elementKey]
                ? (override[elementKey] as any)[k]
                : {}),
            }
          }, {}),
        }
      }, {} as any),
    }

    const cssStyles = `
    ${mapStylesToCSS(styles.css || {})}

    &:hover {
      ${mapStylesToCSS(styles[':hover'] || {})}
    }

    &:focus {
      ${mapStylesToCSS(styles[':focus'] || {})}
    }

    &:invalid {
      ${mapStylesToCSS(styles[':invalid'] || {})}
    }

    &:nth-child(even) {
      ${mapStylesToCSS(styles[':nth-child(even)'] || {})}
    }

    &:nth-child(odd) {
      ${mapStylesToCSS(styles[':nth-child(odd)'] || {})}
    }

    &:active {
      ${mapStylesToCSS(styles[':active'] || {})}
    }

    &::selection {
      ${mapStylesToCSS(styles['::selection'] || {})}
    }
    &:disabled {
      ${mapStylesToCSS(styles[':disabled'] || {})}
    }

    &[disabled] {
      ${mapStylesToCSS(styles[':disabled'] || {})}
    }

    &[readonly] {
      ${mapStylesToCSS(styles[':readonly'] || {})}
    }

  `

    return {
      computedStyle: styles.css,
      cssStyles,
    }
  }
