import styled, { useTheme } from 'styled-components'
import { Text } from 'view/components/Text'
import { IoAddOutline } from 'react-icons/io5'
import { Button } from 'for-web'

export const Overview = () => {
  const theme = useTheme()
  return (
    <Content>
      <TodoItems>
        <TodoItem>
          <TodoHeader>
            <IconWrapper>
              <IoAddOutline color={theme.colors.brand} size={24} />
            </IconWrapper>
            <Text.H3>Add Integration</Text.H3>
          </TodoHeader>
          <Text.Body1>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores et quas molestias excepturi sint
          </Text.Body1>
          <TodoFooter>
            <Button label="Start" />
          </TodoFooter>
        </TodoItem>
      </TodoItems>
    </Content>
  )
}

const TodoFooter = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 24px;
  background: rgba(153, 120, 37, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
`

const TodoHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const TodoItem = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 256px;
  border-radius: 2px;
  border: ${({ theme }) => theme.border};
  background: linear-gradient(
    60deg,
    rgba(153, 120, 37, 0.02),
    rgba(153, 120, 37, 0.06)
  );
`

const TodoItems = styled.div``

const Content = styled.div``
