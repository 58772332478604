import React, { ReactNode } from 'react'
import { Div, P } from '../html'
import { Stylesheet, useStylesheet } from '../stylesheet/stylesheet'
import { useCSSStyles } from '../stylesheet/util'

export type LabelProps = {
  description?: string
  descriptionData?: Record<string, string>
  error?: boolean
  errorLabel?: string | string[]
  errorLabelData?: Record<string, string>
  errorDataTestId?: string
  label: string
  optional?: boolean
  labelData?: Record<string, string>
  renderChildren?: ReactNode | (() => ReactNode)
  style?: Partial<Stylesheet['formLabel']>
  sublabel?: string
  sublabelData?: Record<string, string>
}

export const FieldLabel = (props: LabelProps) => {
  // Styles
  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'formLabel')(props.style)

  // Error
  const errorLabels = Array.isArray(props.errorLabel)
    ? props.errorLabel
    : [props.errorLabel]

  return (
    <Div {...getCSSStyle('wrapper')}>
      <Div {...getCSSStyle('labelTextWrapper')}>
        <P
          {...getCSSStyle({
            labelText: true,
            labelTextError: props.error,
          })}
          data={props.labelData}
        >
          {props.label}
        </P>
        {props.optional === true && (
          <P
            {...getCSSStyle({
              optionalText: true,
            })}
          >
            {`(optional)`}
          </P>
        )}
      </Div>

      {props.sublabel && (
        <P {...getCSSStyle('sublabelText')} data={props.sublabelData}>
          {props.sublabel}
        </P>
      )}
      {props.error &&
        errorLabels.map((errorLabel) => (
          <P
            key={errorLabel}
            label={errorLabel || 'fieldError'}
            data={props.errorLabelData}
            dataTestId={props.errorDataTestId}
            dataValue={errorLabel}
            {...getCSSStyle('errorLabel')}
          />
        ))}
      {props.renderChildren &&
        (typeof props.renderChildren === 'function' ? (
          props.renderChildren()
        ) : (
          <>{props.renderChildren}</>
        ))}
    </Div>
  )
}
