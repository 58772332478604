import React from 'react'
import { Div } from '../../html'
import { Stylesheet, useStylesheet } from '../../stylesheet/stylesheet'
import { useCSSStyles } from '../../stylesheet/util'

export type Props = {
  style?: Partial<Stylesheet['staticText']>
  text: string
  value: string | null | undefined
  htmlInputProps?: React.InputHTMLAttributes<HTMLInputElement>
}

export const StaticText = (props: Props) => {
  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'staticText')(props.style)
  return (
    <Div {...getCSSStyle({ wrapper: true })}>
      <Div>{props.text}</Div>
      <Div>{props.value}</Div>
    </Div>
  )
}
