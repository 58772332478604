export type DataState = {}

const initialDataState: DataState = {}

type Action = any

export const dataReducer = (
  state: DataState = initialDataState,
  action: Action,
): DataState => {
  switch (action.type) {
    default:
      return state
  }
}
