import { Button, Props as ButtonProps } from 'for-web/components/button/Button'
import { Modal as SharedModal } from 'for-web/components/modal/Modal'
import { ReactNode } from 'react'
import { IoMdClose } from 'react-icons/io'
import styled, { CSSProperties, useTheme } from 'styled-components'
import { Text } from './Text'

export type Props = {
  isOpen: boolean
  close: () => void
  clickAway?: () => void
  style?: CSSProperties
  children: ReactNode
  title: string
  footer?: ModalFooterProps
  sidebar?: ReactNode
}

export const Modal = (props: Props) => {
  const theme = useTheme()
  return (
    <SharedModal
      open={props.isOpen}
      onClose={props.clickAway || (() => {})}
      style={{
        innerWrapper: {
          width: 400,
          display: 'flex',
          flexDirection: 'column',
          background: theme.colors.background,
          height: '100%',
          padding: 0,
          overflow: 'hidden',
          // border: theme.border,
          ...(props.style || {}),
        },
      }}
    >
      <ModalHeader>
        <Text.H3>{props.title}</Text.H3>
        <IoMdClose
          color={theme.colors.primaryText}
          size={24}
          onClick={props.close}
          style={{ cursor: 'pointer' }}
        />
      </ModalHeader>

      <ModalContent>{props.children}</ModalContent>
    </SharedModal>
  )
}

type ModalFooterProps = {
  left?: Array<(key: string | number) => ReactNode>
  buttons: Array<ButtonProps>
  borderTop?: boolean
}

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  flex: 0 0 64px;
  border-bottom: ${({ theme }) => theme.border};
`

const ModalContent = styled.div`
  flex: 1;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`
