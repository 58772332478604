import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { BsChevronDown } from 'react-icons/bs'
import {
  MdOutlineAnalytics,
  MdOutlineIntegrationInstructions,
  MdOutlineSpaceDashboard,
} from 'react-icons/md'
import { RiLogoutCircleLine } from 'react-icons/ri'
import { AiOutlineFileText } from 'react-icons/ai'
import { ReactNode } from 'react'
import { Text } from 'view/components/Text'
import { SimplePopover, TextInput } from 'for-web'

export const Scaffold = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const navItems: Array<{
    label: string
    path: string
    icon: (params: { size: number; color: string }) => ReactNode
  }> = [
    {
      label: 'Overview',
      path: '/overview',
      icon: (params) => <MdOutlineSpaceDashboard {...params} />,
    },
    {
      label: 'Training',
      path: '/training',
      icon: (params) => <MdOutlineAnalytics {...params} />,
    },
    {
      label: 'Generate Resume',
      path: '/cv',
      icon: (params) => <AiOutlineFileText {...params} />,
    },
    {
      label: 'Integrations',
      path: '/integrations',
      icon: (params) => <MdOutlineIntegrationInstructions {...params} />,
    },
  ]

  const profileSettings: Array<{
    label: string
    path: string
  }> = [
    {
      label: 'Profile',
      path: '/profile',
    },
    {
      label: 'Settings',
      path: '/settings',
    },
    {
      label: 'Billing',
      path: '/billing',
    },
  ]

  console.log(location)

  return (
    <Wrapper>
      <SidebarWrapper>
        <SidebarHeader>
          <Logo src="/assets/logo-icon.png" />
          <Text.H1
            style={{
              marginLeft: 12,
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            Freeway
          </Text.H1>
        </SidebarHeader>
        <NavigationWrapper>
          {navItems.map((item, key) => {
            const isActive = item.path.startsWith(location.pathname)
            return (
              <NavigationItem
                onClick={() => {
                  navigate(item.path)
                }}
                style={
                  isActive
                    ? {
                        opacity: 1,
                        background: 'rgba(153, 120, 37, 0.04)',
                      }
                    : { opacity: 0.4 }
                }
                key={key}
              >
                <NavigationIcon>
                  {item.icon({
                    size: 20,
                    color: 'white',
                    // color: isActive ? '#F8D5B0' : 'white',
                  })}
                </NavigationIcon>
                <Text.Body1
                  style={
                    {
                      // color: isActive ? '#F8D5B0' : undefined,
                    }
                  }
                >
                  {item.label}
                </Text.Body1>
                {isActive && <ActiveBar />}
              </NavigationItem>
            )
          })}
        </NavigationWrapper>
        <SidebarFooter>
          <NavigationItem>
            <NavigationIcon>
              <RiLogoutCircleLine size={20} color="white" />
            </NavigationIcon>
            <Text.Body1>Logout</Text.Body1>
          </NavigationItem>
        </SidebarFooter>
      </SidebarWrapper>
      <ContentWrapper>
        <Header>
          <TextInput
            value=""
            htmlInputProps={{
              placeholder: 'Search...',
            }}
            style={{
              wrapper: {
                maxWidth: 300,
                marginRight: 'auto',
              },
            }}
          />
          <AccountProgress>
            <Text.Body1 style={{ fontSize: 12, opacity: 0.4, marginBottom: 6 }}>
              Profile Setup · 3 of 5
            </Text.Body1>
            <ProgressBar>
              <Bar />
            </ProgressBar>
          </AccountProgress>
          <SimplePopover
            render={() => (
              <UserPanel>
                {profileSettings.map((item, key) => {
                  return (
                    <NavigationItem
                      style={{
                        padding: '0 16px',
                        opacity: 1,
                      }}
                      key={key}
                    >
                      <Text.Body1>{item.label}</Text.Body1>
                    </NavigationItem>
                  )
                })}
              </UserPanel>
            )}
            style={{
              popover: {
                top: 24,
              },
            }}
            trigger={({ onClick }) => (
              <UserAccount onClick={onClick}>
                <AvatarWrapper>
                  <Avatar src="/assets/avatar.png" />
                </AvatarWrapper>
                <BsChevronDown color="rgba(255,255,255,0.2)" size={16} />
              </UserAccount>
            )}
          />
        </Header>
        <Content>
          <Outlet />
        </Content>
      </ContentWrapper>
    </Wrapper>
  )
}

const UserPanel = styled.div`
  width: 192px;
  padding: 8px 0;
  background: linear-gradient(-45deg, #121315, #1e2024);
  border: ${({ theme }) => theme.border};
`

const UserAccount = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Bar = styled.div`
  background: ${({ theme }) => theme.colors.brand};
  height: 100%;
  border-radius: 3px;
  width: 40%;
`

const ProgressBar = styled.div`
  height: 6px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.06);
`

const AccountProgress = styled.div`
  flex: 0 0 192px;
  margin-right: 48px;
  margin-top: -12px;
`

const Content = styled.div`
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ActiveBar = styled.div`
  background: #f8d5b0;
  height: 100%;
  width: 1px;
  position: absolute;
  right: -1px;
  top: 0;
`

const SidebarFooter = styled.div`
  margin-top: auto;
  border-top: ${({ theme }) => theme.border};
  width: 100%;
  padding: 12px 0;
`

const NavigationItem = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  transition: all ease 0.3s;
  padding: 0 32px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.03);
  }
`

const NavigationIcon = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 16px 32px;
`

const Logo = styled.img`
  width: 20px;
`

const NavigationWrapper = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 24px;
  margin-top: 48px;
  flex: 1;
`

const SidebarHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 32px;
`

const AvatarWrapper = styled.div`
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.02),
    rgba(255, 255, 255, 0.1)
  );
  border: ${({ theme }) => theme.border};
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

const Avatar = styled.img`
  width: 56px;
`

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const SidebarWrapper = styled.div`
  flex: 0 0 256px;
  height: 100%;
  padding: 32px 0 0 0;
  border-right: ${({ theme }) => theme.border};
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  background: linear-gradient(-45deg, #121315, #1e2024);
  width: 100%;
  height: 100vh;
  display: flex;
`
