import React from 'react'
import { ReactNode } from 'react'
import { createGlobalStyle } from 'styled-components'
import { ForWebProvider } from 'for-web/stylesheet/provider'
import { ColorScheme } from 'for-web/stylesheet/theme'
import { sharedStylesheet } from './shared.stylesheet'
import { sharedThemeConfig } from './shared.theme'

const BaseStyle = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: white;
    height: 100%;
  }

  #root {
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
  }

  body {
    margin: 0;
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  div {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  h1,h2,h3,h4,h5,h6,p,input {
    margin: 0;
    padding: 0;
    color: black;
  }

  .__react_component_tooltip {
    /* font-family: 'Inter', sans-serif; */
  }

  input, button {
    font-size: 14px;
    font-family: 'Inter', sans-serif;
  }

  .MuiPopover-paper {
    overflow: visible !important;
  }

  .MuiPaper-root {
    box-shadow: 1px 1px 4px 1px rgb(0,0,0,0.2) !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`

export type Props = {
  children?: ReactNode
  ignoreTheme?: boolean
  disableBaseStyle?: boolean
  colorScheme?: ColorScheme
}

var mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')

const defaultTheme = mediaQueryList.matches
  ? ColorScheme.Dark
  : ColorScheme.Light

export const ThemeProvider = (props: Props) => {
  const [colorScheme, setColorScheme] = React.useState(defaultTheme)

  const handleThemeChange = () => {
    setColorScheme(
      mediaQueryList.matches ? ColorScheme.Dark : ColorScheme.Light,
    )
  }

  React.useEffect(() => {
    mediaQueryList.addListener(handleThemeChange)
  }, [])

  return (
    <ForWebProvider
      defaultScheme={props.colorScheme || colorScheme}
      userStylesheet={sharedStylesheet}
      themeConfig={sharedThemeConfig}
    >
      {!props.disableBaseStyle && <BaseStyle />}
      {props.children}
    </ForWebProvider>
  )
}
