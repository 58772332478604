import React, { useEffect, useRef } from 'react'
import { Stylesheet, useStylesheet } from '../../stylesheet/stylesheet'
import { useCSSStyles } from '../../stylesheet/util'
import { Div, TextArea as HTMLTextArea } from '../../html'

export type Props = {
  onChange?: (value: string) => void
  style?: Partial<Stylesheet['textarea']>
  textInputStyle?: Partial<Stylesheet['textInput']>
  value: string | null | undefined
  onBlur?: (value: string | null | undefined) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
  hasFocus?: boolean
  disabled?: boolean
  readOnly?: boolean
  error?: boolean
  htmlInputProps?: React.InputHTMLAttributes<HTMLInputElement>
}

export const TextArea = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'textarea')(props.style)
  const getInputStyle = useCSSStyles(stylesheet, 'textInput')(props.style)

  const [internalValue, setIntervalValue] = React.useState(props.value)

  useEffect(() => {
    setIntervalValue(props.value)
  }, [props.value])

  return (
    <Div
      {...getInputStyle({
        wrapper: true,
        disabledWrapper: props.disabled || props.htmlInputProps?.disabled, // TODO FIX
      })}
      onClick={() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }}
    >
      <HTMLTextArea
        computedStyle={{
          ...getInputStyle({
            input: true,
            disabledInput: props.disabled,
          }).computedStyle,
          ...getCSSStyle({
            input: true,
          }).computedStyle,
        }}
        cssStyles={
          getInputStyle({
            input: true,
            disabledInput: props.disabled,
          }).cssStyles +
          getCSSStyle({
            input: true,
          }).cssStyles
        }
        {...(props.htmlInputProps || {})}
        ref={inputRef}
        onFocus={props.onFocus}
        onBlur={() => {
          if (props.onBlur) {
            props.onBlur(internalValue)
          }
        }}
        onChange={(event: any) => {
          const v = event.target.value
          setIntervalValue(v)
          if (props.onChange) {
            props.onChange(v)
          }
        }}
        value={internalValue}
      />
    </Div>
  )
}
