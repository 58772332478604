import { ButtonType } from 'for-web/components/button/Button'
import { Form } from 'for-web/form/Form'
import { FormField, FormFieldType } from 'for-web/form/types'
import { makeFormLens } from 'for-web/form/util'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from 'redux/actions/auth.actions'

type LoginData = {
  email: string
  password: string
}

export const mkLogInLens = makeFormLens<LoginData>()

export const loginFields: Array<FormField<LoginData>> = [
  {
    type: FormFieldType.TextInput,
    label: { label: 'Email' },
    lens: mkLogInLens(['email']),
    htmlInputProps: {
      placeholder: 'Email',
      type: 'email',
    },
    onlyOnBlur: false,
  },
  {
    type: FormFieldType.TextInput,
    label: { label: 'Password' },
    htmlInputProps: {
      type: 'password',
      placeholder: 'Password',
    },
    lens: mkLogInLens(['password']),
    onlyOnBlur: false,
  },
]

export const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)

  const [loginData, setLoginData] = React.useState<LoginData>({
    email: '',
    password: '',
  })

  return (
    <Form<LoginData>
      formFields={loginFields}
      data={loginData}
      onChange={setLoginData}
      buttons={[
        {
          label: loading ? 'Authenticating...' : 'Login',
          type: ButtonType.Primary,
          isDisabled: () => loading,
          override: {
            width: '100%',
            justifyContent: 'center',
            height: 40,
          },
          onClick: () => {
            setLoading(true)
            dispatch(
              login.call({
                json: loginData,
              }),
            )
          },
        },
      ]}
    />
  )
}
