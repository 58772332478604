import {
  GetRequest,
  PostRequest,
  RestMethod,
} from 'for-util/lib/redux/frr/api.types'
import { AppUser } from './database.types'

export enum Endpoints {
  Me = '/me',
  Register = '/register',
  Login = '/login',
}

export type API = {
  [Endpoints.Me]: GetRequest<{
    response: AppUser
  }>
  [Endpoints.Register]: PostRequest<{
    json: {
      email: string
      password: string
      secureNote: string
      firstName: string
      lastName: string
      username: string
    }
    response: {}
  }>
  [Endpoints.Login]: PostRequest<{
    json: {
      email: string
      password: string
    }
    response: {
      user: AppUser
      token: string
    }
  }>
}

export const mapEndpointToMethod: {
  [k in Endpoints]: API[k]['method']
} = {
  [Endpoints.Me]: RestMethod.GET,
  [Endpoints.Login]: RestMethod.POST,
  [Endpoints.Register]: RestMethod.POST,
}
