import { Div, P } from '../html'
import { useStylesheet } from '../stylesheet/stylesheet'
import { useCSSStyles } from '../stylesheet/util'
import { FieldRow } from './FieldRow'
import {
  CommonThreadProps,
  FormFieldGroup,
  GroupField,
  IconActionType,
} from './types'

type FieldGroupT<FormData> = CommonThreadProps<FormData> & {
  field: FormFieldGroup<FormData>
}

export const FieldGroup = <FormData extends {}>(
  props: FieldGroupT<FormData>,
) => {
  const {
    data,
    field: fieldGroup,
    fieldIndex: fieldGroupIndex,
    ...otherProps
  } = props
  // Form styles
  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'formGroup')(fieldGroup.style)

  const commonFieldProps = {
    data,
    ...otherProps,
  }

  const renderGroupField = (
    field: GroupField<FormData>,
    fieldIndex: number,
  ) => (
    <FieldRow
      field={Array.isArray(field) ? field : [field]}
      {...commonFieldProps}
      key={`field-group-${fieldGroupIndex}-${fieldIndex}`}
      fieldIndex={fieldIndex}
    />
  )

  return (
    <Div
      {...getCSSStyle(
        'wrapper',
        fieldGroup.style ? fieldGroup.style.wrapper || {} : {},
      )}
    >
      {fieldGroup.title && (
        <Div {...getCSSStyle('header')}>
          {fieldGroup.title && (
            <P
              {...getCSSStyle(
                'title',
                fieldGroup.style ? fieldGroup.style.title || {} : {},
              )}
            >
              {fieldGroup.title}
            </P>
          )}
          {fieldGroup.iconActions && (
            <Div {...getCSSStyle('actionWrapper')}>
              {fieldGroup.iconActions.map((action, index) => (
                <Div key={index}>
                  {action.type === IconActionType.OnClick ? (
                    <Div onClick={action.onClick} {...getCSSStyle('action')}>
                      {action.show && action.icon}
                    </Div>
                  ) : (
                    action.show && action.popOver
                  )}
                </Div>
              ))}
            </Div>
          )}
        </Div>
      )}
      {'render' in fieldGroup
        ? fieldGroup.render(fieldGroup)
        : fieldGroup.fields.map(renderGroupField)}
    </Div>
  )
}
