import React from 'react'
import { CSSProperties } from 'styled-components'
import { P, Button as HTMLButton } from '../../html'
import { Stylesheet, useStylesheet } from '../../stylesheet/stylesheet'
import { useCSSStyles } from '../../stylesheet/util'
import { Loading } from '../loading/Loading'

export enum ButtonType {
  Secondary = 'Secondary',
  Chromeless = 'Chromeless',
  Primary = 'Primary',
}

const mapTypeToStyleKey: {
  [k in ButtonType]: keyof Partial<Stylesheet['button']>
} = {
  [ButtonType.Chromeless]: 'chromeless',
  [ButtonType.Primary]: 'primary',
  [ButtonType.Secondary]: 'secondary',
}

const mapTypeToStyleLabelKey: {
  [k in ButtonType]: keyof Partial<Stylesheet['button']>
} = {
  [ButtonType.Chromeless]: 'chromelessLabel',
  [ButtonType.Primary]: 'primaryLabel',
  [ButtonType.Secondary]: 'secondaryLabel',
}

export type Props = {
  disabled?: boolean
  label: string
  loading?: boolean
  onClick?: () => void
  override?: CSSProperties // normal styled components styling
  style?: Partial<Stylesheet['button']> // styling must follow strict stylesheet pattern
  alternativeStyleKey?: 'button' | 'formButton'
  type?: ButtonType
}

export const Button = (props: Props) => {
  /* Style hooks */
  const type = props.type || ButtonType.Secondary
  const stylesheet = useStylesheet()

  const getCSSStyle = useCSSStyles(
    stylesheet,
    props.alternativeStyleKey || 'button',
  )(props.style)

  return (
    <HTMLButton
      onClick={props.onClick}
      disabled={props.disabled}
      {...getCSSStyle(['common', mapTypeToStyleKey[type]], props.override)}
    >
      <P
        disabled={props.disabled}
        {...getCSSStyle(['label', mapTypeToStyleLabelKey[type]])}
      >
        {props.label}
      </P>
      {props.loading && (
        <Loading style={getCSSStyle(['spinner']).computedStyle} />
      )}
    </HTMLButton>
  )
}
