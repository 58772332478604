import { UserStylesheet } from 'for-web/stylesheet/stylesheet'
import { DefaultTheme } from 'styled-components'
import {
  Body1Styling,
  Body2Styling,
  H4Styling,
  H5Styling,
  Subtitle1Styling,
} from 'view/components/Text'

export const sharedStylesheet = (theme: DefaultTheme): UserStylesheet => ({
  button: {
    common: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.3s ease',
      border: 'none',
      borderRadius: 4,
      height: 36,
      padding: '0 12px',
      cursor: 'pointer',
      ':disabled': {
        opacity: 0.5,
        cursor: 'default',
      },
    },
    spinner: {
      height: 24,
      width: 24,
      transform: 'scale(0.2)',
      marginTop: -10,
    },
    primary: {
      background: theme.colors.brand,
      ':disabled': {
        background: 'rgba(255,255,255,0.3)',
        opacity: 1,
      },
      ':hover': {
        background: theme.colors.brandDark,
      },
    },
    primaryLabel: {
      fontSize: 13,
      fontWeight: 600,
      fontFamily: 'Outfit',
      textTransform: 'uppercase',
      letterSpacing: '0.04em',
      color: theme.colors.primaryButtonColor,

      ':disabled': {
        color: 'black',
        opacity: 0.6,
      },
    },
    secondary: {
      border: '1px solid rgba(255,255,255,0.2)',
      background: 'transparent',
      ':hover': {
        cursor: 'pointer',
        background: 'rgba(255,255,255,0.06)',
      },
    },
    secondaryLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
      fontFamily: 'Outfit',
      letterSpacing: '0.06em',
      fontWeight: 300,
      color: 'white',
    },
    chromeless: {
      backgroundColor: 'transparent',
      border: 0,
    },
  },
  formButton: {
    common: {
      display: 'flex',
      flex: 1,
      width: '100%',
      alignItems: 'center',
      transition: 'all 0.3s ease',
      border: theme.border,
      borderColor: 'black',
      borderRadius: 4,
      height: 32,
      margin: '16px 0',
      padding: '0 12px',
      cursor: 'pointer',
      ':hover': {
        borderColor: 'black',
      },
      ':disabled': {
        opacity: 0.5,
        cursor: 'default',
      },
    },
    spinner: {
      height: 24,
      width: 24,
      transform: 'scale(0.2)',
      marginTop: -10,
    },
    primary: {
      background: 'none',
      justifyContent: 'center',
      ':disabled': {
        background: 'black',
        opacity: 1,
      },
      ':hover': {
        borderColor: 'black',
      },
      ':active': {
        borderColor: 'black',
      },
    },
    primaryLabel: {
      ...Subtitle1Styling,
      color: 'black',
      ':disabled': {
        color: 'rgba(48, 53, 60, 0.6)',
      },
    },
    secondary: {
      backgroundColor: 'black',
      color: 'black',
    },
    secondaryLabel: {
      fontSize: 12,
      fontWeight: 500,
      color: 'black',
    },
    chromeless: {
      backgroundColor: 'transparent',
      border: 0,
    },
  },
  iconButton: {
    icon: {
      color: 'black',
      height: 16,
      width: 16,
    },
    box: {
      cursor: 'pointer',
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4,
      backgroundColor: 'transparent',
      transition: 'all 0.2s ease-in-out',
      ':hover': {
        backgroundColor: 'black',
      },
    },
    boxActive: {
      backgroundColor: 'black',
    },
  },
  formWrapper: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonContainer: {},
  },
  formSection: {
    title: {
      fontSize: 32,
    },
  },
  formGroup: {
    title: {
      fontSize: 16,
    },
  },
  formLabel: {
    labelTextWrapper: {
      display: 'flex',
      gap: 4,
    },
    labelText: {
      ...H5Styling,
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0.02em',
      color: theme.colors.primaryText,
    },
    optionalText: {
      ...H5Styling,
      fontWeight: 400,
      color: theme.colors.primaryText,
    },
  },
  formRow: {
    item: {
      flex: 1,
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 24,
      flex: 1,
    },
  },
  textInput: {
    wrapper: {
      width: '100%',
      flex: 1,
      background: 'transparent',
      marginTop: 4,
    },
    disabledInput: {},
    input: {
      ...Body1Styling,
      height: 36,
      width: '100%',
      padding: '0 8px',
      margin: 0,
      color: theme.colors.primaryText,
      borderRadius: 2,
      background: 'rgba(255,255,255,0.02)',
      border: theme.border,
      ':hover': {
        boxShadow: theme.boxShadow,
      },
      ':readonly': {
        pointerEvents: 'none',
      },
      ':focus': {
        border: `1px solid rgba(248,214,172, 0.6)  !important`,
        background: 'rgba(248,214,172,0.08)',
        outline: 'none',
      },
      '::selection': {
        background: 'black',
      },
    },
  },
  select: {
    wrapper: {
      width: '100%',
      height: 32,
      // margin: '4px 0',
      cursor: 'pointer',
      position: 'relative',
      display: 'flex',
    },
    iconWrapper: {
      color: 'black',
      flex: 1,
    },
    iconWrapperFocus: {
      textAlign: 'left',
    },
    dropdownIcon: {
      position: 'absolute',
      color: 'black',
      fontSize: 18,
      right: 8,
      margin: '9px 0 0 0',
    },
    dropdownIconFocus: {
      color: 'black',
    },
    disabledInput: {},
    input: {
      ...Body1Styling,
      height: 36,
      width: 140,
      background: 'transparent',
      padding: '0 8px',
      margin: 0,
      color: 'black',
      borderRadius: 4,
      border: theme.border,
      ':hover': {
        boxShadow: theme.boxShadow,
      },
      ':readonly': {
        pointerEvents: 'none',
      },
      ':focus': {
        border: `1px solid ${'black'}  !important`,
        outline: 'none',
      },
      '::selection': {
        background: 'black',
      },
    },
  },
  numberInputIncrement: {
    wrapper: {
      display: 'flex',
      width: '100%',
      flex: 1,
      background: 'transparent',
      marginTop: 4,
      alignItems: 'center',
      position: 'relative',
    },
    rowItem: {
      ...Body2Styling,
      display: 'flex',
      flex: '0 0 48px',
      userSelect: 'none',
    },
    toggleWrapper: {
      display: 'flex',
      flex: '0 0 112px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputFieldWrapper: {
      display: 'flex',
      flex: '0 0 40px',
      margin: '0px 8px',
      userSelect: 'none',
    },
    errorIcon: {
      color: 'black',
      fontSize: 16,
      position: 'absolute',
      right: 20,
      top: `calc(50% - 16px/2)`,
    },
    icon: {
      color: 'black',
    },
  },
  staticText: {
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: 8,
    },
  },
  textarea: {
    input: {
      height: 200,
      padding: 8,
    },
  },
  checkOption: {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      cursor: 'pointer',
      userSelect: 'none',
    },
    label: {
      ...Body2Styling,
      paddingTop: 2,
    },
  },
  modal: {
    outerWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    innerWrapper: {
      padding: 24,
      background: 'white',
      borderRadius: 8,
    },
  },
  table: {
    noResultsLabel: {
      opacity: 0.6,
    },
    noResultsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '300px',
    },
    loadingWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '300px',
    },
    loading: {
      transform: 'scale(0.3)',
    },
    tableWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      borderRadius: 4,
      overflow: 'hidden',
      background: 'rgba(255,255,255,0.02)',
    },
    listWrapper: {
      flex: 1,
    },
    rowCell: {},
    rowWrapper: {
      padding: '0 16px',
      display: 'flex',
      alignItems: 'center',
      height: 64,
      transition: 'all ease 0.15s',
      cursor: 'pointer',

      ':hover': {
        filter: 'darken(90%)',
      },

      ':nth-child(even)': {
        background: 'rgba(255,255,255,0.02)',
      },
    },
    rowText: {
      ...Body1Styling,
      whiteSpace: 'nowrap',
      color: theme.colors.primaryText,
    },
    headerOuterWrapper: {},
    headerWrapper: {
      display: 'flex',
      alignItems: 'center',
      height: 40,
      padding: '0 16px',
      borderBottom: theme.border,
    },
    headerCell: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.colors.primaryText,
      fontWeight: 400,
      textTransform: 'uppercase',
      fontSize: 12,
      opacity: 0.7,
      display: 'flex',
      alignItems: 'center',
    },
    headerText: {
      ...Body2Styling,
      fontWeight: 400,
      fontSize: 10,
      letterSpacing: '0.2em',
      opacity: 0.7,
      color: theme.colors.primaryText,
    },
    descriptionOuterWrapper: {
      color: 'inherit',
    },
    descriptionIcon: {
      width: 14,
      fontSize: 14,
      color: 'inherit',
      marginLeft: '5px',
    },
    descriptionIconWrapper: {
      color: 'inherit',
      height: 16,
    },
    descriptionPopup: {
      padding: 20,
    },
    descriptionText: {
      fontSize: 12,
    },
  },
  checkbox: {
    outer: {
      flex: '0 0 14px',
      width: 14,
      height: 14,
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid rgba(154, 160, 179, 0.5)',
      transition: 'all 0.3s ease',
    },
    outerHover: {
      border: '1px solid #FF5E00',
      boxShadow: '0 0 0 2px rgba(255, 94, 0, 0.3)',
    },
    outerActive: {
      border: '1px solid ' + 'black',
      backgroundColor: 'black',
    },
    icon: {
      color: 'white',
      fontSize: 10,
      marginLeft: 1,
    },
  },
  radioButton: {
    outer: {
      flex: '0 0 14px',
      width: 14,
      height: 14,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid rgba(154, 160, 179, 0.5)',
      transition: 'all 0.3s ease',
    },
    inner: {
      borderRadius: '50%',
      width: 8,
      height: 8,
      transition: 'all 0.3s ease',
    },
    innerActive: {
      backgroundColor: 'black',
    },
    outerHover: {
      border: '1px solid #FF5E00',
      boxShadow: '0 0 0 2px rgba(255, 94, 0, 0.3)',
    },
    outerActive: {
      border: '1px solid ' + 'black',
    },
  },
  menu: {
    wrapper: {
      maxHeight: 400,
      overflowY: 'scroll',
      padding: '8px 0',
      width: 172,
      height: 'auto',
      userSelect: 'none',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
    },
    subMenuWrapper: {
      position: 'absolute',
      left: 172,
      zIndex: 9000,
      width: 176,
      borderRadius: 4,
      padding: '8px 0',
    },
    item: {
      padding: '0 16px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      height: '32px',
      flex: '1 0 32px',
      position: 'relative',
      justifyContent: 'flex-start',
      cursor: 'pointer',
    },
    itemDisabled: {
      opacity: 0.3,
      pointerEvents: 'none',
    },
    checkmarkIcon: {
      width: 14,
    },
    label: {
      fontSize: 12,
      marginLeft: 'auto',
      opacity: 0.7,
      padding: 4,
      borderRadius: 4,
      width: 24,
      height: 24,
      lineHeight: '18px !important',
      textAlign: 'center',
      color: theme.colors.primaryText,
    },
  },
  selectIcon: {
    wrapper: {
      display: 'flex',
      width: '100%',
      height: 48,
      padding: '8px 8px',
      gap: 16,
    },
    iconWrapper: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 28,
      height: 28,
      borderRadius: 5,
      ':hover': {
        backgroundColor: `${'black'} !important`,
      },
    },
    selectedIconWrapper: {
      cursor: 'default',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 28,
      height: 28,
      borderRadius: 5,
      backgroundColor: `${'black'} !important`,
    },
  },
})
