import { ButtonType } from 'for-web/components/button/Button'
import { Form } from 'for-web/form/Form'
import { FormField, FormFieldType } from 'for-web/form/types'
import { makeFormLens } from 'for-web/form/util'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { register } from 'redux/actions/auth.actions'

type RegisterationData = {
  username: string
  email: string
  password: string
  firstName: string
  lastName: string
}

export const mkRegistrationLens = makeFormLens<RegisterationData>()

export const registerFields: Array<FormField<RegisterationData>> = [
  {
    type: FormFieldType.TextInput,
    label: { label: 'First name' },
    lens: mkRegistrationLens(['firstName']),
    htmlInputProps: {
      placeholder: 'First name',
    },
  },
  {
    type: FormFieldType.TextInput,
    label: { label: 'Last name' },
    lens: mkRegistrationLens(['lastName']),
    htmlInputProps: {
      placeholder: 'Last name',
    },
  },
  {
    type: FormFieldType.TextInput,
    label: { label: 'Username' },
    lens: mkRegistrationLens(['username']),
    htmlInputProps: {
      placeholder: 'Username',
      type: 'username',
    },
  },
  {
    type: FormFieldType.TextInput,
    label: { label: 'Email' },
    htmlInputProps: {
      type: 'email',
      placeholder: 'Email',
    },
    lens: mkRegistrationLens(['email']),
  },
  {
    type: FormFieldType.TextInput,
    label: { label: 'Password' },
    htmlInputProps: {
      type: 'password',
      placeholder: 'Password',
    },
    lens: mkRegistrationLens(['password']),
  },
]

export const Registration = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)

  const [registrationData, setRegistrationData] =
    React.useState<RegisterationData>({
      username: '',
      email: '',
      password: '',
      firstName: '',
      lastName: '',
    })

  return (
    <Form<RegisterationData>
      formFields={registerFields}
      data={registrationData}
      onChange={setRegistrationData}
      buttons={[
        {
          label: loading ? 'Registering...' : 'Register',
          type: ButtonType.Primary,
          isDisabled: () => loading,
          override: {
            width: '100%',
            justifyContent: 'center',
            height: 40,
          },
          onClick: () => {
            setLoading(true)
            dispatch(
              register.call({
                json: {
                  ...registrationData,
                  secureNote: '',
                },
                meta: {
                  navigate,
                },
              }),
            )
          },
        },
      ]}
    />
  )
}
