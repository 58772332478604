import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from '../../theme/ThemeProvider'
import { createContext, useContext, useEffect } from 'react'
import { AuthState } from '../../redux/reducers/auth.reducer'
import { Landing, Unauthenticated } from './Unauthenticated'
import { me, noToken } from '../../redux/actions/auth.actions'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { AppUser } from 'generated/database.types'
import { Registration } from './Register'
import { Login } from './Login'
import { Overview } from 'view/pages/Overview'
import { ColorScheme } from 'for-web/stylesheet/theme'
import { Scaffold } from './Scaffold'
import { Integrations } from 'view/pages/Integrations'

const AppUserContext = createContext<AppUser | undefined>(undefined)
AppUserContext.displayName = 'AppUserContext'

export const useAppUser = (): AppUser => {
  const user = useContext(AppUserContext)
  if (!user) {
    throw new Error(`Auth user not found.`)
  }
  return user
}

export const Authenticator = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const user = useSelector((state: { auth: AuthState }) => state.auth.user)

  useEffect(() => {
    if (user.isInitial()) {
      let token = localStorage.getItem('auth_token')
      const paramToken = new URLSearchParams(`${window.location.search}`).get(
        'token',
      )
      token = token || paramToken
      if (token) {
        localStorage.setItem('auth_token', token)
        dispatch(me.call({}))
        if (paramToken) {
          navigate('/')
        }
      } else {
        dispatch(noToken())
      }
    }
  }, [dispatch, user, navigate])

  // useEffect(() => {
  //   if (user.isSuccess()) {
  //     const intervalId = setInterval(() => {
  //       dispatch(me.call({}))
  //     }, 1000 * 60)

  //     return () => {
  //       clearInterval(intervalId)
  //     }
  //   }

  //   return () => {}
  // }, [user, dispatch])

  if (user.isInitial()) {
    return <></>
  }

  return user.toOption().fold(
    <ThemeProvider
      ignoreTheme
      key="unauthorized"
      colorScheme={ColorScheme.Dark}
    >
      <Routes>
        <Route path="/" element={<Unauthenticated />}>
          <Route path="" element={<Landing />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Registration />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </ThemeProvider>,
    (u) => (
      <ThemeProvider key="authorized">
        <AppUserContext.Provider value={u}>
          <Routes>
            <Route path="/" element={<Scaffold />}>
              <Route path="overview" element={<Overview />} />
              <Route path="integrations" element={<Integrations />} />
            </Route>
            <Route path="*" element={<Navigate to="/overview" />} />
          </Routes>
        </AppUserContext.Provider>
      </ThemeProvider>
    ),
  )
}
