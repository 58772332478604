import { Div, P } from 'for-web/html'
import React, { ReactNode, Ref, useRef, useState } from 'react'
import { IconBaseProps } from 'react-icons'
import { IoMdCheckmark } from 'react-icons/io'
import { MdArrowRight } from 'react-icons/md'
import { Stylesheet, useStylesheet } from '../../stylesheet/stylesheet'
import { useCSSStyles } from '../../stylesheet/util'
import { Checkbox } from '../option/Checkbox'

export enum MenuMode {
  Dark = 'dark',
  Light = 'light',
}

export enum MenuType {
  Default = 'default',
  MultiSelect = 'multiSelect',
  SingleSelect = 'singleSelect',
}

export type MenuItemT<Data = {}> = {
  key?: string
  text: string
  subItems?: Array<MenuItemT<Data>>
  onClick?: (params: { close: () => void }) => void
  icon?: (iconProps: IconBaseProps) => ReactNode
  isDisabled?: boolean
  isSelected?: boolean
  label?: string
} & Data

const MenuItem = <Data extends {} = {}>(props: {
  mode: MenuMode
  type: MenuType
  item: MenuItemT<Data>
  onClick: (e: any) => void
  style?: Partial<Stylesheet['menu']>
}) => {
  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'menu')(props.style)
  const [hover, setHover] = useState(false)
  const selectedIcon =
    props.type === MenuType.MultiSelect ? (
      <Checkbox isActive={true} hover={hover} />
    ) : props.type === MenuType.SingleSelect ? (
      <IoMdCheckmark
        style={
          getCSSStyle({
            iconDark: props.mode === MenuMode.Dark,
            iconLight: props.mode === MenuMode.Light,
            checkmarkIcon: true,
          }).computedStyle
        }
      />
    ) : null

  const unselectedIcon =
    props.type === MenuType.MultiSelect ? (
      <Checkbox isActive={false} hover={hover} />
    ) : null

  return (
    <Div
      onClick={props.onClick}
      {...getCSSStyle([
        'item',
        props.mode === MenuMode.Dark ? 'itemDark' : 'itemLight',
        props.item.isDisabled ? 'itemDisabled' : 'item',
      ])}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      {props.type !== MenuType.Default && (
        <Div
          style={{
            width: 16,
            marginLeft: 0,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {props.item.isSelected ? selectedIcon : unselectedIcon}
        </Div>
      )}
      {props.item.icon &&
        props.item.icon({
          style: getCSSStyle({
            iconDark: props.mode === MenuMode.Dark,
            iconLight: props.mode === MenuMode.Light,
          }).computedStyle,
        })}

      <P
        {...getCSSStyle([
          props.mode === MenuMode.Dark ? 'textDark' : 'textLight',
        ])}
      >
        {props.item.text}
      </P>

      {props.item.label && (
        <Div {...getCSSStyle('label')}>
          {props.item.label.toLocaleUpperCase()}
        </Div>
      )}
      {props.item.subItems && (
        <MdArrowRight
          style={{
            ...getCSSStyle([
              props.mode === MenuMode.Dark ? 'iconDark' : 'iconLight',
            ]).computedStyle,
            width: 18,
            height: 18,
            marginLeft: 'auto',
          }}
        />
      )}
      {hover && props.item.subItems && (
        <Div
          style={{
            ...getCSSStyle([
              props.mode === MenuMode.Dark ? 'wrapperDark' : 'wrapperLight',
            ]).computedStyle,
            ...getCSSStyle(['subMenuWrapper']).computedStyle,
          }}
        >
          {props.item.subItems.map((item, k) => (
            <MenuItem
              type={props.type}
              item={item}
              key={item.key || item.text}
              mode={props.mode}
              onClick={() => item.onClick && item.onClick({ close: () => {} })}
            />
          ))}
        </Div>
      )}
    </Div>
  )
}
export type MenuProps<Data = {}> = {
  mode: MenuMode
  items: Array<MenuItemT<Data>>
  style?: Partial<Stylesheet['menu']>
  selected?: Array<string> | string
  type?: MenuType
  onClick?: (params: { item: MenuItemT<Data>; close: () => void }) => void
  close: () => void
}

// type Props<T extends unknown> = {
//   data: T
//   children: ReactNode
//   onSubmit: (data: T) => void
// }

// // const MyForm = forwardRef(
// //   <T extends unknown>(
// //     { data, children, onSubmit }: Props<T>,
// //     ref: Ref<HTMLFormElement>,
// //   ) => {
// //     const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
// //       // onSubmit(...);
// //     }
// //     // some code here ...
// //     return (
// //       <form ref={ref} onSubmit={handleSubmit}>
// //         {children}
// //       </form>
// //     )
// //   },
// // )

export const Menu = <Data extends {} = {}>(props: MenuProps<Data>) => {
  const { close } = props
  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'menu')(props.style)
  const type = props.type || MenuType.Default

  return (
    <Div
      style={{
        ...getCSSStyle([
          'wrapper',
          props.mode === MenuMode.Dark ? 'wrapperDark' : 'wrapperLight',
        ]).computedStyle,
        overflowY: props.items.some((m) => m.subItems) ? 'visible' : 'scroll',
        ...(props.items.some((m) => m.subItems) ? { maxHeight: 'none' } : {}),
      }}
    >
      {props.items.map((item, index) => {
        return (
          <MenuItem
            type={type}
            key={index}
            item={
              props.selected && item.key
                ? {
                    ...item,
                    isSelected:
                      props.selected === item.key ||
                      props.selected.includes(item.key),
                  }
                : item
            }
            style={props.style}
            onClick={
              item.onClick
                ? (e: any) => {
                    e.stopPropagation()
                    if (item.onClick) {
                      item.onClick({ close })
                    }
                  }
                : (e: any) => {
                    e.stopPropagation()
                    if (props.onClick) {
                      props.onClick({ item, close })
                    }
                  }
            }
            mode={props.mode}
          />
        )
      })}
    </Div>
  )
}

export const ForwardRefMenu = React.forwardRef(
  <Data extends {} = {}>(
    props: MenuProps<Data>,
    ref?: Ref<HTMLElement> | undefined,
  ) => {
    const localRef = useRef(null)
    const inputRef = ref || localRef
    const { close } = props
    const stylesheet = useStylesheet()
    const getCSSStyle = useCSSStyles(stylesheet, 'menu')(props.style)
    const type = props.type || MenuType.Default

    return (
      <Div
        ref={inputRef}
        style={{
          ...getCSSStyle([
            'wrapper',
            props.mode === MenuMode.Dark ? 'wrapperDark' : 'wrapperLight',
          ]).computedStyle,
          overflowY: props.items.some((m) => m.subItems) ? 'visible' : 'scroll',
          ...(props.items.some((m) => m.subItems) ? { maxHeight: 'none' } : {}),
        }}
      >
        {props.items.map((item, index) => {
          return (
            <MenuItem
              type={type}
              key={index}
              item={
                props.selected && item.key
                  ? {
                      ...item,
                      isSelected:
                        props.selected === item.key ||
                        props.selected.includes(item.key),
                    }
                  : item
              }
              style={props.style}
              onClick={
                item.onClick
                  ? () => {
                      if (item.onClick) {
                        item.onClick({ close })
                      }
                    }
                  : () => {
                      if (props.onClick) {
                        props.onClick({ item, close })
                      }
                    }
              }
              mode={props.mode}
            />
          )
        })}
      </Div>
    )
  },
) as unknown as <T extends unknown>(
  props: MenuProps<T> & { ref: Ref<HTMLElement> },
) => JSX.Element
