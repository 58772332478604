import styled, { useTheme } from 'styled-components'
import { Text } from 'view/components/Text'
import { IoAddOutline } from 'react-icons/io5'
import { Button, Table } from 'for-web'
import { ButtonType } from 'for-web/components/button/Button'
import { TableColumns } from 'for-web/components/table/Table'
import { IntegrationModal } from './AddIntegration.modal'
import { useState } from 'react'

type DataRow = {
  name: string
  lastSynced: number
  state: string
}

const columns: TableColumns<DataRow> = [
  {
    label: 'Name',
    dataKey: 'name',
  },
  {
    label: 'Last Synced',
    width: 256,
    customValue: (v) => new Date(v.lastSynced).toISOString(),
  },
  {
    label: 'State',
    width: 144,
    customRender: (v) => (
      <StateItem>
        <Dot />
        <Text.Body1
          style={{
            color: '#1be1af',
            fontSize: 13,
          }}
        >
          {v.state}
        </Text.Body1>
      </StateItem>
    ),
  },
  {
    label: 'Action',
    width: 64,
    customRender: () => <Button label="Edit" />,
  },
]

export const Integrations = () => {
  const theme = useTheme()

  const [isOpen, setIsOpen] = useState(false)

  const data: Array<DataRow> = [
    {
      name: 'Hack the Box',
      lastSynced: 213913919,
      state: 'Active',
    },
    {
      name: 'Hack the Box',
      lastSynced: 213913919,
      state: 'Active',
    },
    {
      name: 'Hack the Box',
      lastSynced: 213913919,
      state: 'Active',
    },
    {
      name: 'Hack the Box',
      lastSynced: 213913919,
      state: 'Active',
    },
  ]

  return (
    <Content>
      <IntegrationModal
        modalProps={{
          title: 'Add integration',
          isOpen,
          close: () => {
            setIsOpen(false)
          },
        }}
      />
      <TitleWrapper>
        <Text.H3>My Integrations</Text.H3>
        <Button
          label="Add new"
          type={ButtonType.Primary}
          onClick={() => {
            setIsOpen(true)
          }}
        />
      </TitleWrapper>
      <Table data={data} columns={columns} />
    </Content>
  )
}

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #1be1af;
  margin-right: 8px;
`

const StateItem = styled.div`
  display: flex;
  align-items: center;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
