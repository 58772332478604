import { Div, P } from 'for-web/html'
import { ReactNode, useState } from 'react'
import { Stylesheet, useStylesheet } from '../../stylesheet/stylesheet'
import { useCSSStyles } from '../../stylesheet/util'
import { Checkbox } from '../option/Checkbox'

export type Props = {
  value: boolean | null | undefined
  text: string
  icon?: ReactNode
  onChange: (v: boolean) => void
  style?: Partial<Stylesheet['checkOption']> // styling must follow strict stylesheet pattern
}

export const CheckOption = (props: Props) => {
  const stylesheet = useStylesheet()

  const getCssStyle = useCSSStyles(stylesheet, 'checkOption')(props.style)
  const [hover, setHover] = useState(false)

  return (
    <Div
      {...getCssStyle(['wrapper'])}
      onClick={() => props.onChange(!(props.value || false))}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Checkbox isActive={props.value || false} hover={hover} />
      <Div {...getCssStyle(['icon'])}>{props.icon}</Div>
      <P {...getCssStyle(['label'])}>{props.text}</P>
    </Div>
  )
}
