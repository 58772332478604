import * as React from 'react'
import {
  CSSProperties as sc_CSSProperties,
  DefaultTheme,
  useTheme,
} from 'styled-components'

export type CSSProperties = sc_CSSProperties &
  Partial<{
    ':nth-child(even)': CSSProperties
    ':nth-child(odd)': CSSProperties
    ':hover': CSSProperties
    ':focus': CSSProperties
    ':disabled': CSSProperties
    ':readonly': CSSProperties
    ':active': CSSProperties
    '::selection': CSSProperties
    '@media-mobile': CSSProperties
    '@animation': string
  }>

export type Stylesheet = {
  formRow: {
    wrapper: CSSProperties
    item: CSSProperties
  }
  icon: {
    icon: CSSProperties
  }
  formGroup: {
    title: CSSProperties
    wrapper: CSSProperties
    description: CSSProperties
    actionWrapper: CSSProperties
    action: CSSProperties
    header: CSSProperties
  }
  formStaticField: {
    wrapper: CSSProperties
    title: CSSProperties
    text: CSSProperties
    button: CSSProperties
  }
  formSection: {
    content: CSSProperties
    contentWrapper: CSSProperties
    description: CSSProperties
    introduction: CSSProperties
    title: CSSProperties
    titleWrapper: CSSProperties
    wrapper: CSSProperties
  }
  formSectionRight: {
    wrapper: CSSProperties
    editLink: CSSProperties
    editIcon: CSSProperties
  }
  formWrapper: {
    wrapper: CSSProperties
    content: CSSProperties
    buttonContainer: CSSProperties
  }
  formFieldMultiInput: {
    wrapper: CSSProperties
    item: CSSProperties
  }
  formFieldReadOnly: {
    image: CSSProperties
    item: CSSProperties
    label: CSSProperties
    textAreaItem: CSSProperties
    textAreaValue: CSSProperties
    value: CSSProperties
    valueHighlighted: CSSProperties
    wrapper: CSSProperties
  }

  formButton: {
    chromeless: CSSProperties
    primary: CSSProperties
    label: CSSProperties
    secondary: CSSProperties
    common: CSSProperties
    spinner: CSSProperties
    primaryLabel: CSSProperties
    secondaryLabel: CSSProperties
    chromelessLabel: CSSProperties
  }

  button: {
    chromeless: CSSProperties
    primary: CSSProperties
    label: CSSProperties
    secondary: CSSProperties
    common: CSSProperties
    spinner: CSSProperties
    primaryLabel: CSSProperties
    secondaryLabel: CSSProperties
    chromelessLabel: CSSProperties
  }

  iconButton: {
    icon: CSSProperties
    iconActive: CSSProperties
    boxIcon: CSSProperties
    boxIconActive: CSSProperties
    box: CSSProperties
    boxActive: CSSProperties
  }
  formLabel: {
    descriptionIcon: CSSProperties
    descriptionIconWrapper: CSSProperties
    descriptionPopup: CSSProperties
    descriptionText: CSSProperties
    errorIcon: CSSProperties
    errorIconWrapper: CSSProperties
    errorLabel: CSSProperties
    labelText: CSSProperties
    labelTextError: CSSProperties
    labelTextWrapper: CSSProperties
    sublabelText: CSSProperties
    wrapper: CSSProperties
    optionalText: CSSProperties
  }
  textInput: {
    disabledInput: CSSProperties
    disabledWrapper: CSSProperties
    errorHook: CSSProperties
    errorInput: CSSProperties
    errorWrapper: CSSProperties
    hook: CSSProperties
    input: CSSProperties
    errorIcon: CSSProperties
    errorIconWrapper: CSSProperties
    prefix: CSSProperties
    postfix: CSSProperties
    readOnlyHook: CSSProperties
    readOnlyInput: CSSProperties
    readOnlyWrapper: CSSProperties
    wrapper: CSSProperties
  }
  select: {
    wrapper: CSSProperties
    wrapperFocus: CSSProperties
    dropdownIcon: CSSProperties
    iconWrapper: CSSProperties
    iconWrapperFocus: CSSProperties
    dropdownIconFocus: CSSProperties
    disabledInput: CSSProperties
    input: CSSProperties
    inputFocus: CSSProperties
    inputHover: CSSProperties
  }
  numberInputIncrement: {
    wrapper: CSSProperties
    input: CSSProperties
    rowItem: CSSProperties
    icon: CSSProperties
    toggleWrapper: CSSProperties
    inputFieldWrapper: CSSProperties
    errorIcon: CSSProperties
  }
  textarea: {
    input: CSSProperties
  }
  staticText: {
    wrapper: CSSProperties
  }
  popover: {
    wrapper: CSSProperties
    popover: CSSProperties
  }
  radioButton: {
    outer: CSSProperties
    inner: CSSProperties
    outerActive: CSSProperties
    innerActive: CSSProperties
    outerHover: CSSProperties
    innerHover: CSSProperties
  }
  checkbox: {
    outer: CSSProperties
    icon: CSSProperties
    outerActive: CSSProperties
    outerHover: CSSProperties
  }
  modal: {
    outerWrapper: CSSProperties
    innerWrapper: CSSProperties
  }
  table: {
    tableWrapper: CSSProperties
    listWrapper: CSSProperties
    rowWrapper: CSSProperties
    rowCell: CSSProperties
    rowText: CSSProperties
    headerWrapper: CSSProperties
    headerCell: CSSProperties
    headerText: CSSProperties
    loadingWrapper: CSSProperties
    noResultsWrapper: CSSProperties
    noResultsLabel: CSSProperties
    loading: CSSProperties
    headerOuterWrapper: CSSProperties
    descriptionOuterWrapper: CSSProperties
    descriptionIcon: CSSProperties
    descriptionIconWrapper: CSSProperties
    descriptionPopup: CSSProperties
    descriptionText: CSSProperties
  }
  selectIcon: {
    wrapper: CSSProperties
    iconWrapper: CSSProperties
    selectedIconWrapper: CSSProperties
  }
  checkOption: {
    wrapper: CSSProperties
    icon: CSSProperties
    label: CSSProperties
  }

  menu: {
    wrapper: CSSProperties
    subMenuWrapper: CSSProperties
    wrapperLight: CSSProperties
    wrapperDark: CSSProperties
    item: CSSProperties
    textLight: CSSProperties
    textDark: CSSProperties
    itemLight: CSSProperties
    itemDark: CSSProperties
    itemDisabled: CSSProperties
    checkmarkIcon: CSSProperties
    iconLight: CSSProperties
    iconDark: CSSProperties
    label: CSSProperties
  }
}

const defaultStylesheet: Stylesheet = {
  formSection: {
    content: {},
    contentWrapper: {},
    description: {},
    introduction: {},
    title: {},
    titleWrapper: {},
    wrapper: {},
  },
  icon: {
    icon: {},
  },
  formStaticField: {
    wrapper: {},
    title: {},
    text: {},
    button: {},
  },
  formSectionRight: {
    wrapper: {},
    editIcon: {},
    editLink: {},
  },
  formButton: {
    chromeless: {},
    primary: {},
    label: {},
    primaryLabel: {},
    secondaryLabel: {},
    chromelessLabel: {},
    secondary: {},
    common: {},
    spinner: {},
  },
  formRow: {
    wrapper: {},
    item: {},
  },
  formGroup: {
    title: {},
    wrapper: {},
    description: {},
    actionWrapper: {},
    action: {},
    header: {},
  },
  formWrapper: {
    wrapper: {},
    content: {},
    buttonContainer: {},
  },
  formFieldMultiInput: {
    wrapper: {},
    item: {},
  },
  formFieldReadOnly: {
    image: {},
    item: {},
    label: {},
    textAreaItem: {},
    textAreaValue: {},
    value: {},
    valueHighlighted: {},
    wrapper: {},
  },
  table: {
    tableWrapper: {},
    listWrapper: {},
    rowWrapper: {},
    rowCell: {},
    rowText: {},
    headerWrapper: {},
    headerCell: {},
    headerText: {},
    loadingWrapper: {},
    noResultsWrapper: {},
    noResultsLabel: {},
    loading: {},
    headerOuterWrapper: {},
    descriptionOuterWrapper: {},
    descriptionIcon: {},
    descriptionIconWrapper: {},
    descriptionPopup: {},
    descriptionText: {},
  },
  modal: {
    outerWrapper: {},
    innerWrapper: {},
  },
  popover: {
    popover: {},
    wrapper: {},
  },
  checkbox: {
    outer: {},
    outerActive: {},
    outerHover: {},
    icon: {},
  },
  radioButton: {
    outer: {},
    inner: {},
    outerActive: {},
    innerActive: {},
    outerHover: {},
    innerHover: {},
  },
  button: {
    chromeless: {},
    primary: {},
    label: {},
    primaryLabel: {},
    secondaryLabel: {},
    chromelessLabel: {},
    secondary: {},
    common: {},
    spinner: {},
  },

  iconButton: {
    icon: {},
    iconActive: {},
    boxIcon: {},
    boxIconActive: {},
    box: {},
    boxActive: {},
  },
  formLabel: {
    descriptionIcon: {},
    descriptionIconWrapper: {},
    descriptionPopup: {},
    descriptionText: {},
    errorIcon: {},
    errorIconWrapper: {},
    errorLabel: {},
    labelText: {},
    labelTextError: {},
    labelTextWrapper: {},
    sublabelText: {},
    wrapper: {},
    optionalText: {},
  },
  textInput: {
    disabledInput: {},
    disabledWrapper: {},
    errorHook: {},
    errorInput: {},
    errorWrapper: {},
    hook: {},
    input: {},
    errorIcon: {},
    errorIconWrapper: {},
    prefix: {},
    postfix: {},
    readOnlyHook: {},
    readOnlyInput: {},
    readOnlyWrapper: {},
    wrapper: {},
  },
  numberInputIncrement: {
    wrapper: {},
    input: {},
    rowItem: {},
    icon: {},
    toggleWrapper: {},
    inputFieldWrapper: {},
    errorIcon: {},
  },
  staticText: {
    wrapper: {},
  },
  textarea: {
    input: {},
  },
  select: {
    wrapper: {},
    wrapperFocus: {},
    dropdownIcon: {},
    iconWrapper: {},
    iconWrapperFocus: {},
    disabledInput: {},
    dropdownIconFocus: {},
    input: {},
    inputFocus: {},
    inputHover: {},
  },
  selectIcon: {
    wrapper: {},
    iconWrapper: {},
    selectedIconWrapper: {},
  },
  checkOption: {
    wrapper: {},
    icon: {},
    label: {},
  },
  menu: {
    wrapper: {},
    subMenuWrapper: {},
    wrapperLight: {},
    wrapperDark: {},
    item: {},
    textLight: {},
    textDark: {},
    itemLight: {},
    itemDark: {},
    itemDisabled: {},
    iconLight: {},
    iconDark: {},
    checkmarkIcon: {},
    label: {},
  },
}

export type UserStylesheet = {
  [k in keyof Stylesheet]?: Partial<Stylesheet[k]>
}

export const StylesheetContext =
  React.createContext<Stylesheet>(defaultStylesheet)

export const configureStylesheet = (userStylesheet: UserStylesheet) =>
  (Object.keys(defaultStylesheet) as Array<keyof Stylesheet>).reduce(
    (components, componentKey) => ({
      ...components,
      [componentKey]: Object.keys(defaultStylesheet[componentKey]).reduce(
        (componentStyles, styleKey) => {
          const isCSSStyles =
            typeof (defaultStylesheet as any)[componentKey][styleKey] ===
            'string'

          const userColorSchemeVal =
            (userStylesheet as any)[componentKey]?.[styleKey] ||
            (isCSSStyles && '') ||
            {}

          return {
            ...componentStyles,
            [styleKey]: isCSSStyles
              ? userColorSchemeVal
              : {
                  ...(defaultStylesheet as any)[componentKey][styleKey],
                  ...userColorSchemeVal,
                },
          }
        },
        {},
      ),
    }),
    {},
  ) as Stylesheet

export const useStylesheet = (): Stylesheet => {
  const stylesheet = React.useContext(StylesheetContext)
  if (!stylesheet) {
    throw new Error('Stylesheet not defined')
  }
  return stylesheet
}

export const StylesheetProvider = (props: {
  children: React.ReactNode
  userStylesheet: (theme: DefaultTheme) => UserStylesheet
}) => {
  const theme = useTheme()

  return (
    <StylesheetContext.Provider
      value={configureStylesheet(props.userStylesheet(theme))}
    >
      {props.children}
    </StylesheetContext.Provider>
  )
}
