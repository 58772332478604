import { createStyled } from './stylesheet/util'

const HtmlElements = {
  button: createStyled('button'),
  h1: createStyled('h1'),
  h2: createStyled('h2'),
  h3: createStyled('h3'),
  h4: createStyled('h4'),
  h5: createStyled('h5'),
  h6: createStyled('h6'),
  li: createStyled('li'),
  option: createStyled('option'),
  p: createStyled('p'),
  a: createStyled('a'),
  div: createStyled('div'),
  input: createStyled('input'),
  textarea: createStyled('textarea'),
  select: createStyled('select'),
}

export const Div = HtmlElements.div
export const A = HtmlElements.a
export const H1 = HtmlElements.div.h1
export const H2 = HtmlElements.h2
export const H3 = HtmlElements.h3
export const H4 = HtmlElements.h4
export const H5 = HtmlElements.h5
export const H6 = HtmlElements.h6
export const Li = HtmlElements.li
export const Option = HtmlElements.option
export const P = HtmlElements.p
export const Button = HtmlElements.button
export const Input = HtmlElements.input
export const TextArea = HtmlElements.textarea
export const Select = HtmlElements.select
