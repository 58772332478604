import { useState } from 'react'
import styled from 'styled-components'
import { Modal, Props as ModalProps } from 'view/components/Modal'
import { Text } from 'view/components/Text'

export const IntegrationModal = (props: {
  modalProps: Omit<ModalProps, 'children'>
}) => {
  const [selectedIntegration, setSelectedIntegration] = useState<string>()

  const integrations: Array<{
    icon: string
    name: string
    id: string
  }> = [
    {
      icon: 'htb-logo.png',
      name: 'Hack the box',
      id: 'htb',
    },
    {
      icon: 'htb-logo.png',
      name: 'Hack the box',
      id: 'htb-2',
    },
    {
      icon: 'htb-logo.png',
      name: 'Hack the box',
      id: 'htb-3',
    },
  ]

  return (
    <Modal
      {...props.modalProps}
      style={{
        height: 600,
        width: 800,
      }}
    >
      <Integrations>
        {integrations.map((i, index) => (
          <IntegrationItem
            key={index}
            onClick={() => {
              setSelectedIntegration(i.id)
            }}
            style={
              selectedIntegration === i.id
                ? {
                    background: 'rgba(255,255,255,0.04)',
                  }
                : {}
            }
          >
            <IntegrationIcon src={`/assets/${i.icon}`} />
            <Text.H4>{i.name}</Text.H4>
          </IntegrationItem>
        ))}
      </Integrations>
    </Modal>
  )
}

const Integrations = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

const IntegrationIcon = styled.img`
  width: 64px;
`

const IntegrationItem = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: ${({ theme }) => theme.border};
  cursor: pointer;
  transition: all ease 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.02);
  }
`
