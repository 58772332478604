import {
  RemoteData,
  initial,
  success,
  failure,
} from '@devexperts/remote-data-ts'
import { AppUser } from '../../generated/database.types'
import {
  me,
  login,
  Logout,
  NoToken,
  ViewActionType,
  SetAuthUser,
} from '../actions/auth.actions'

export type AuthState = {
  user: RemoteData<{}, AppUser>
}

const initialDataState: AuthState = {
  user: initial,
}

type Action =
  | typeof me.action.all
  | typeof login.action.all
  | Logout
  | NoToken
  | SetAuthUser

export const authReducer = (
  state: AuthState = initialDataState,
  action: Action,
): AuthState => {
  switch (action.type) {
    case login.types.failure:
      return {
        ...state,
        user: failure({}),
      }

    case ViewActionType.Logout:
      return initialDataState

    case ViewActionType.SetAuthUser:
      return {
        ...state,
        user: success(action.payload),
      }

    case me.types.success:
      return {
        ...state,
        user: success(action.payload),
      }

    case ViewActionType.NoToken:
      return {
        ...state,
        user: failure({}),
      }

    default:
      return state
  }
}
