import { ReactNode, useState } from 'react'
import {
  AutoSizer as _AutoSizer,
  List as _List,
  ListRowProps,
} from 'react-virtualized'
import { CSSProperties, useTheme } from 'styled-components'
import { useStylesheet, Stylesheet } from '../../stylesheet/stylesheet'
import { useCSSStyles } from '../../stylesheet/util'
import { Div, P } from '../../html'
import { Loading } from '../loading/Loading'
import { SimplePopover } from '../popover/SimplePopover'

const AutoSizer = _AutoSizer as any
const List = _List as any

export type TableColumn<T extends {}> = {
  // TODO - fix typing
  dataKey?: keyof T
  customRender?: (
    row: T,
    params: {
      isHovered: boolean
      setIsHovered: (v: boolean) => void
      setIsActive: (v: boolean) => void
      isActive: boolean
    },
    value?: T[keyof T],
  ) => ReactNode
  customValue?: (row: T) => string
  label: string
  labelInfo?: string
  width?: number
  isAmountValue?: boolean
  isHighlightedForSearch?: boolean
}

export type TableColumns<T extends {}> = Array<TableColumn<T>>

type Props<T extends {}> = {
  data: Array<T>
  columns: TableColumns<T>
  onRowClick?: (row: T) => void
  noResultsLabel?: string
  loading?: boolean
  style?: Partial<Stylesheet['table']>
  getRowStyle?: (row: T) => CSSProperties
}

const Row = <T extends {}>(props: {
  row: T
  columns: TableColumns<T>
  style?: Partial<Stylesheet['table']>
  onRowClick?: (row: T) => void
  getRowStyle?: (row: T) => CSSProperties
  params: ListRowProps
}) => {
  const { row, params } = props
  const [isHovered, setIsHovered] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'table')(props.style)

  const rowSpecificStyle = {
    cursor: !!props.onRowClick ? 'pointer' : 'default',
  } as any

  return (
    <Div
      {...getCSSStyle(
        'rowWrapper',
        props.getRowStyle
          ? { ...props.getRowStyle(row), ...rowSpecificStyle }
          : rowSpecificStyle,
      )}
      onMouseEnter={() => {
        if (isActive) {
          setIsHovered(false)
          setIsActive(false)
        } else {
          setIsHovered(true)
        }
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      style={params.style}
      onClick={() => {
        if (props.onRowClick) {
          props.onRowClick(row)
        }
      }}
    >
      {props.columns.map((c, index) => {
        const value = c.dataKey ? row[c.dataKey] : undefined
        return (
          <Div
            {...getCSSStyle('rowCell', {
              flex: c.width ? `0 0 ${c.width}px` : 1,
              overflow: 'hidden',
            })}
            key={c.dataKey || index}
          >
            {c.customRender ? (
              c.customRender(
                row,
                { isHovered, isActive, setIsActive, setIsHovered },
                c.dataKey ? row[c.dataKey] : undefined,
              )
            ) : (
              <P {...getCSSStyle('rowText')}>
                {c.customValue ? c.customValue(row) : value}
              </P>
            )}
          </Div>
        )
      })}
    </Div>
  )
}

export const Table = <T extends {}>(props: Props<T>) => {
  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'table')(props.style)

  const rowRenderer = (params: ListRowProps) => (
    <Row
      params={params}
      {...props}
      row={props.data[params.index]}
      key={params.key}
    />
  )

  return (
    <Div {...getCSSStyle('tableWrapper')}>
      <Div {...getCSSStyle('headerOuterWrapper')}>
        <Div {...getCSSStyle('headerWrapper')}>
          {props.columns.map((c, index) => {
            return (
              <Div
                {...getCSSStyle('headerCell', {
                  flex: c.width ? `0 0 ${c.width}px` : 1,
                  overflow: 'hidden',
                })}
                key={c.dataKey || index}
              >
                <P {...getCSSStyle('headerText')}>{c.label}</P>
                {c.labelInfo !== undefined && c.labelInfo.trim().length > 0 ? (
                  <SimplePopover
                    style={{
                      wrapper: getCSSStyle('descriptionOuterWrapper')
                        .computedStyle,
                    }}
                    trigger={({ onClick }) => (
                      <Div {...getCSSStyle('descriptionIconWrapper')}>
                        {/* TODO ADD ICON */}
                      </Div>
                    )}
                    render={() => (
                      <Div {...getCSSStyle('descriptionPopup')}>
                        <P
                          {...getCSSStyle('descriptionText')}
                          label={c.labelInfo}
                        />
                      </Div>
                    )}
                  ></SimplePopover>
                ) : null}
              </Div>
            )
          })}
        </Div>
      </Div>

      <Div {...getCSSStyle('listWrapper')}>
        {props.loading ? (
          <Div {...getCSSStyle('loadingWrapper')}>
            <Loading style={getCSSStyle('loading').computedStyle} />
          </Div>
        ) : props.data.length === 0 ? (
          <Div {...getCSSStyle('noResultsWrapper')}>
            <P
              {...getCSSStyle('noResultsLabel')}
              label={props.noResultsLabel || 'No results found.'}
            />
          </Div>
        ) : (
          <AutoSizer>
            {({ height, width }: any) => (
              <List
                height={height}
                rowHeight={64}
                rowRenderer={rowRenderer}
                width={width}
                rowCount={props.data.length}
              />
            )}
          </AutoSizer>
        )}
      </Div>
    </Div>
  )
}
