import { ReactNode } from 'react'
import { DefaultTheme } from 'styled-components'
import { StylesheetProvider, UserStylesheet } from './stylesheet'
import {
  ColorScheme,
  ColorSchemeProvider,
  StyledThemeProvider,
  ThemeConfig,
} from './theme'

export const ForWebProvider = (props: {
  children: ReactNode
  defaultScheme?: ColorScheme
  themeConfig: ThemeConfig
  userStylesheet: (theme: DefaultTheme) => UserStylesheet
}) => {
  return (
    <ColorSchemeProvider defaultScheme={props.defaultScheme}>
      <StyledThemeProvider themeConfig={props.themeConfig}>
        <StylesheetProvider userStylesheet={props.userStylesheet}>
          {props.children}
        </StylesheetProvider>
      </StyledThemeProvider>
    </ColorSchemeProvider>
  )
}
