import { Button, ButtonType } from 'for-web/components/button/Button'
import { Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

export const Landing = () => {
  const navigate = useNavigate()
  return (
    <DefaultWrapper>
      <Button
        label="Login"
        type={ButtonType.Primary}
        onClick={() => {
          navigate('/login')
        }}
      />
      <Button
        label="Register"
        type={ButtonType.Secondary}
        onClick={() => {
          navigate('/register')
        }}
      />
      <Button
        label="Demo"
        type={ButtonType.Secondary}
        onClick={() => {
          navigate('/demo')
        }}
      />
    </DefaultWrapper>
  )
}

export const Unauthenticated = () => {
  return (
    <UnauthenticatedOuterWrapper>
      <UnauthenticatedInnerWrapper>
        <Logo src="/assets/logo.png" />
        <Content>
          <Outlet />
        </Content>
      </UnauthenticatedInnerWrapper>
    </UnauthenticatedOuterWrapper>
  )
}

const Content = styled.div`
  width: 100%;
`

const Logo = styled.img`
  width: 172px;
  margin-bottom: 36px;
`

const DefaultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

const UnauthenticatedOuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background};
`

const UnauthenticatedInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  flex: 1;
  background: ${({ theme }) => theme.colors.cardBackground};
  width: 100%;
  padding: 48px;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 0 256px 64px rgba(248, 214, 172, 0.04);
`
