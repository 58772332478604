import Popover from '@material-ui/core/Popover'
import { Div } from 'for-web/html'
import { Stylesheet, useStylesheet } from 'for-web/stylesheet/stylesheet'
import { useCSSStyles } from 'for-web/stylesheet/util'
import React, { forwardRef, Ref, useEffect, useImperativeHandle } from 'react'

// const useStyles = makeStyles(() =>
//   createStyles({
//     typography: {
//       padding: 4,
//     },
//   }),
// )

type Props = {
  trigger: (props: {
    onClick: (e: any) => void
    isOpen: boolean
  }) => React.ReactNode
  render: (p: { close: () => void }) => React.ReactNode
  style?: Partial<Stylesheet['popover']>
  transformOrigin?: {
    vertical?: 'top' | 'bottom' | 'center'
    horizontal?: 'left' | 'right' | 'center'
  }
  onClose?: () => void
}

export const SimplePopover = forwardRef(
  (props: Props, ref: Ref<{ close: () => void }>) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null,
    )
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    useImperativeHandle(ref, () => ({ close }))

    const stylesheet = useStylesheet()
    const getCSSStyle = useCSSStyles(stylesheet, 'popover')(props.style)

    const handleClose = (e: any) => {
      // move to props.onClose() in case propagation should be possible.
      e.stopPropagation()
      setAnchorEl(null)
      if (props.onClose) {
        props.onClose()
      }
    }

    const close = () => {
      setAnchorEl(null)
      if (props.onClose) {
        props.onClose()
      }
    }

    const isOpen = Boolean(anchorEl)
    const id = () => (isOpen ? 'simple-popover' : undefined)

    return (
      <Div {...getCSSStyle(['wrapper'])}>
        {props.trigger({ onClick: handleClick, isOpen })}
        <Popover
          disableAutoFocus={true}
          disableEnforceFocus={true}
          id={id()}
          style={getCSSStyle(['popover']).computedStyle}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: props.transformOrigin?.vertical || 'top',
            horizontal: props.transformOrigin?.horizontal || 'center',
          }}
        >
          {props.render({ close })}
        </Popover>
      </Div>
    )
  },
)
