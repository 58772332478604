import React from 'react'
import { Field } from './Field'
import { FieldScrollableWrapper } from './FieldScrollableWrapper'
import { CommonThreadProps, SingleFormField } from './types'

type Props<FormData> = CommonThreadProps<FormData> & {
  field: SingleFormField<FormData>
  onError?: (error: { error: string; fieldId: string }) => void
  isNotScrollable?: boolean
}
// ------------------------------------

export const FieldRowItem = <FormData extends {}>(props: Props<FormData>) => {
  const { data, field, fieldIndex, onChange } = props

  return (
    <FieldScrollableWrapper key={`field-${fieldIndex}`} style={field.style}>
      <Field
        data={data}
        field={field as SingleFormField<FormData>}
        fieldIndex={fieldIndex}
        // hasFocus={field.lens.id() === errorFieldId}
        onChange={onChange}
      />
    </FieldScrollableWrapper>
  )
}
