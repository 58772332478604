import { BiMinus, BiPlus } from 'react-icons/bi'
import { MdError } from 'react-icons/md'
import { Div, P } from '../../html'
import { useStylesheet } from '../../stylesheet/stylesheet'
import { useCSSStyles } from '../../stylesheet/util'
import { IconButton, IconButtonType } from '../button/IconButton'
import { NumberInput, Props as NumberInputProps } from './NumberInput'

export type Props = NumberInputProps & {
  leftLabel: string
  rightLabel: string
}
export const NumberInputIncrement = (props: Props) => {
  const { leftLabel, rightLabel, error, ...otherProps } = props

  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(
    stylesheet,
    'numberInputIncrement',
  )(props.style)

  return (
    <Div {...getCSSStyle('wrapper')}>
      <P {...getCSSStyle('rowItem')} style={{ userSelect: 'none' }}>
        {props.leftLabel}
      </P>
      <Div {...getCSSStyle('toggleWrapper')}>
        <IconButton
          type={IconButtonType.Box}
          onClick={() => props.onChange(!props.value ? 0 : props.value - 1)}
          icon={(props) => <BiMinus {...props} />}
          override={{
            ...getCSSStyle(['icon']).computedStyle,
          }}
        />
        <Div {...getCSSStyle('inputFieldWrapper')}>
          <NumberInput
            {...otherProps}
            style={{
              input: {
                textAlign: 'center',
              },
            }}
          />
          {props.error && (
            <MdError style={getCSSStyle('errorIcon').computedStyle} />
          )}
        </Div>
        <IconButton
          type={IconButtonType.Box}
          onClick={() => props.onChange(!props.value ? 1 : props.value + 1)}
          icon={(props) => <BiPlus {...props} />}
          override={{
            ...getCSSStyle(['icon']).computedStyle,
          }}
        />
      </Div>
      <P {...getCSSStyle('rowItem')} style={{ userSelect: 'none' }}>
        {props.rightLabel}
      </P>
    </Div>
  )
}
