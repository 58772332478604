import { ClickAwayListener } from '@material-ui/core'
import { getRect } from 'for-web/util/useRect'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { MdError } from 'react-icons/md'
import { RiArrowDownSLine } from 'react-icons/ri'
import { Div, Input as HTMLInput } from '../../html'
import { Stylesheet, useStylesheet } from '../../stylesheet/stylesheet'
import { useCSSStyles } from '../../stylesheet/util'
import { ForwardRefMenu, MenuItemT, MenuMode, MenuType } from '../menu/Menu'

export type Props = {
  onChange?: (value: string) => void
  asyncOnChange?: (value: string) => void
  options: Array<MenuItemT>
  style?: Partial<Stylesheet['select']>
  inputStyle?: Partial<Stylesheet['textInput']>
  popoverStyle?: Partial<Stylesheet['popover']>
  required?: boolean | ((formData: any) => boolean)
  searchable?: boolean
  value: string | null | undefined
  onBlur?: (value: string | null | undefined) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
  hasFocus?: boolean
  disabled?: boolean
  readOnly?: boolean
  error?: boolean
  htmlInputProps?: React.InputHTMLAttributes<HTMLInputElement>
}

//luke dont delete
const computeMargin = (margin: string | number | undefined): number => {
  if (!margin) return 0
  if (typeof margin === 'number') return margin

  const marginArray = margin.split(' ')

  const parseMargin = (marginString: string): number => {
    if (marginString.includes('px')) {
      return parseInt(marginString.substring(0, marginString.length - 2))
    } else {
      return parseInt(marginArray[0])
    }
  }

  switch (marginArray.length) {
    case 1: {
      return parseMargin(marginArray[0])
    }
    case 2: {
      return parseMargin(marginArray[1])
    }
    case 3: {
      return parseMargin(marginArray[1])
    }
    case 4: {
      return parseMargin(marginArray[3])
    }
    default:
      throw Error('Invalid Margin')
  }
}

export const Select = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const menuRef = useRef(null)
  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'select')(props.style)
  const getInputStyle = useCSSStyles(stylesheet, 'textInput')(props.inputStyle)
  const getMenuStyle = useCSSStyles(stylesheet, 'popover')(props.popoverStyle)
  const [items, setItems] = useState<MenuItemT[]>([])
  const [internalValue, setInternalValue] = React.useState(
    props.options.find((op) => op.key === props.value),
  )
  const [searchString, setSearchString] = React.useState(
    props.options.find((op) => op.key === props.value)?.text || '',
  )
  const [searching, setSearching] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [menuTopPosition, setMenuTopPosition] = React.useState<number>(0)
  const [hover, setHover] = useState(false)
  const textInputStyle = getCSSStyle(
    {
      input: true,
      disabledInput: props.disabled,
      inputHover: hover && !isOpen && !searching,
      inputFocus: isOpen || searching,
    },
    props.searchable ? {} : { caretColor: 'transparent', cursor: 'pointer' },
  )

  let inputMarginLeft: number = computeMargin(
    textInputStyle.computedStyle.margin,
  )

  useLayoutEffect(() => {
    const menuElement = menuRef.current
    const inputElement = inputRef.current

    if (!menuElement || !inputElement) {
      return
    }
    const menuHeight = getRect(menuElement).height
    const inputRect = getRect(inputElement)

    if (window.innerHeight - inputRect.bottom > menuHeight) {
      setMenuTopPosition(8 + inputRect.height)
    } else if (
      window.innerHeight -
        (window.innerHeight - (inputRect.top + inputRect.height)) >
      menuHeight
    ) {
      setMenuTopPosition(-menuHeight)
    } else {
      setMenuTopPosition(8 + inputRect.height)
      // setMenuTopPosition(-menuHeight)
    }
  }, [isOpen])

  useEffect(() => {
    setSearching(false)
  }, [props.value])

  useEffect(() => {
    const v = props.options.find((op) => op.key === props.value)
    setInternalValue(v)
    if (!searching) {
      setSearchString(
        props.options.find((op) => op.key === props.value)?.text || '',
      )
      inputRef.current?.blur()
    }
  }, [props.value, searching, props.options])

  useEffect(() => {
    setItems(
      props.options
        .filter(
          (op) =>
            !searching ||
            searchString.length < 1 ||
            op.text.toLowerCase().includes(searchString.toLowerCase()),
        )
        .map(
          (option): MenuItemT => ({
            ...option,
            onClick: ({ close }) => {
              setInternalValue(option)
              const val = option.key || option.text
              props.onChange?.(val)
              props.asyncOnChange?.(val)
              setHover(false)
              close()
            },
            isSelected: option.key === internalValue?.key,
          }),
        ),
    )
  }, [internalValue, searchString, props, searching])

  return (
    <Div
      style={{ position: 'relative', display: 'flex' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Div
        {...getCSSStyle({
          wrapper: true,
          wrapperFocus: hover || isOpen || searching,
        })}
        onClick={() => {
          if (props.searchable) inputRef.current?.focus()
        }}
      >
        {props.error && (
          <MdError style={getInputStyle('errorIcon').computedStyle} />
        )}
        <HTMLInput
          {...textInputStyle}
          {...(props.htmlInputProps || {})}
          size={searchString.length}
          ref={inputRef}
          onFocus={(e: React.FocusEvent<HTMLInputElement, Element>) => {
            if (props.onFocus) props.onFocus(e)
            if (props.searchable) inputRef.current?.select()
          }}
          onBlur={props.onBlur}
          onChange={(event: any) => {
            if (props.searchable) {
              const v = event.target.value
              setSearching(true)
              if (v.length === 0 && props.onChange && props.required !== true) {
                props.onChange('')
              }
              setSearchString(v)
            }
          }}
          onClick={() => {
            if (!isOpen) inputRef.current?.focus()
            setIsOpen((o) => !o)
          }}
          placeholder={internalValue?.text || 'Select an option'}
          value={searchString}
        />
        <Div
          {...getCSSStyle({
            iconWrapper: true,
            iconWrapperFocus: hover || isOpen || searching,
          })}
        >
          <RiArrowDownSLine
            onClick={() => inputRef.current?.click()}
            style={
              getCSSStyle({
                dropdownIcon: true,
                dropdownIconFocus: hover || isOpen || searching,
              }).computedStyle
            }
          />
        </Div>
      </Div>
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <ForwardRefMenu
            ref={menuRef}
            mode={MenuMode.Dark}
            type={MenuType.SingleSelect}
            items={
              items.length > 0
                ? items
                : [{ text: 'No results', key: '', onClick: () => {} }]
            }
            close={() => {
              inputRef.current?.blur()
              setIsOpen(false)
            }}
            style={{
              wrapper: {
                width: inputRef.current?.offsetWidth || 0,
                position: 'absolute',
                top: menuTopPosition,
                zIndex: 1,
                marginLeft: inputMarginLeft,
              },
            }}
            {...getMenuStyle({})}
          />
        </ClickAwayListener>
      )}
    </Div>
  )
}
