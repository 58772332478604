import React, { ReactNode, useEffect } from 'react'
import { useCSSStyles } from '../stylesheet/util'
import { Stylesheet, useStylesheet } from '../stylesheet/stylesheet'
import { Div } from '../html'

/*
 * Render field function
 */

type FieldScrollableWrapperProps = {
  children: ReactNode
  style?: Partial<Stylesheet['formRow']>
}

export const FieldScrollableWrapper = (props: FieldScrollableWrapperProps) => {
  /* Styles */
  const stylesheet = useStylesheet()
  const getRowStyle = useCSSStyles(stylesheet, 'formRow')(props.style)

  const fieldRef = React.createRef<HTMLDivElement>()

  /* Render form field */

  return (
    <Div ref={fieldRef} {...getRowStyle('item')}>
      {props.children}
    </Div>
  )
}
