import { CSSProperties } from 'for-web/stylesheet/stylesheet'
import styled from 'styled-components'
import { mapStylesToCSS } from 'for-web/stylesheet/util'
import { fontSizes } from 'theme/shared.theme'

export const H1Styling: CSSProperties = {
  fontFamily: 'Outfit',
  fontWeight: 500,
  fontSize: fontSizes.heading1,
}

export const H2Styling: CSSProperties = {
  fontFamily: 'Outfit',
  fontWeight: 500,
  fontSize: fontSizes.heading2,
}

export const H3Styling: CSSProperties = {
  fontFamily: 'Outfit',
  fontWeight: 500,
  fontSize: fontSizes.heading3,
}
export const H4Styling: CSSProperties = {
  fontFamily: 'Outfit',
  fontWeight: 600,
  lineHeight: '20px',
  fontSize: fontSizes.heading4,
}

export const H5Styling: CSSProperties = {
  fontFamily: 'Outfit',
  fontWeight: 600,
  fontSize: fontSizes.heading5,
}

export const H6Styling: CSSProperties = {
  fontFamily: 'Outfit',
  fontWeight: 700,
  fontSize: fontSizes.heading6,
}

export const Body1Styling: CSSProperties = {
  fontFamily: 'Outfit',
  fontWeight: 200,
  lineHeight: '20px',
  fontSize: fontSizes.body1,
}

export const Body2Styling: CSSProperties = {
  fontFamily: 'Outfit',
  fontWeight: 400,
  fontSize: fontSizes.body2,
}

export const Subtitle1Styling: CSSProperties = {
  fontFamily: 'Outfit',
  fontWeight: 400,
  fontSize: fontSizes.subtitle1,
}

export const Subtitle2Styling: CSSProperties = {
  fontFamily: 'Outfit',
  fontWeight: 400,
  fontSize: fontSizes.subtitle2,
}

export const Caption1Styling: CSSProperties = {
  fontFamily: 'Menlo, sans-serif',
  fontWeight: 400,
  fontSize: fontSizes.caption1,
}

export const Caption2Styling: CSSProperties = {
  fontFamily: 'Menlo, sans-serif',
  fontWeight: 400,
  fontSize: fontSizes.caption2,
}

const H1 = styled.p`
  ${mapStylesToCSS(H1Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`

const H2 = styled.p`
  ${mapStylesToCSS(H2Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`

const H3 = styled.p`
  ${mapStylesToCSS(H3Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`

const H4 = styled.p`
  ${mapStylesToCSS(H4Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`

const H5 = styled.p`
  ${mapStylesToCSS(H5Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`

const H6 = styled.p`
  ${mapStylesToCSS(H6Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`
const Body1 = styled.p`
  ${mapStylesToCSS(Body1Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`
const Body2 = styled.p`
  ${mapStylesToCSS(Body2Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`
const Subtitle1 = styled.p`
  ${mapStylesToCSS(Subtitle1Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`

const Subtitle2 = styled.p`
  ${mapStylesToCSS(Subtitle2Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`

const Caption1 = styled.p`
  ${mapStylesToCSS(Caption1Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`

const Caption2 = styled.p`
  ${mapStylesToCSS(Caption2Styling)}
  color: ${({ theme }) => theme.colors.primaryText};
`

export const Text = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Body1,
  Body2,
  Subtitle1,
  Subtitle2,
  Caption1,
  Caption2,
}
