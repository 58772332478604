import React, { useContext, ReactNode } from 'react'
import {
  DefaultTheme,
  ThemeProvider as _StyledColorSchemeProvider,
} from 'styled-components'

export enum ColorScheme {
  Light = 'light',
  Dark = 'dark',
}

export type ThemeConfig = Record<ColorScheme, DefaultTheme>

const ColorSchemeContext = React.createContext<ColorScheme>(ColorScheme.Light)
ColorSchemeContext.displayName = 'ColorScheme'

export const useColorScheme = (): ColorScheme => {
  const colorScheme = useContext(ColorSchemeContext)
  // return 'light'
  if (!colorScheme) {
    throw new Error(`Color scheme not found`)
  }

  return colorScheme
}

const SetColorSchemeContext = React.createContext<(value: ColorScheme) => void>(
  () => {},
)
ColorSchemeContext.displayName = 'SetColorScheme'

export const useSetColorScheme = (): ((value: ColorScheme) => void) => {
  const colorScheme = useContext(SetColorSchemeContext)
  // return 'light'
  if (!colorScheme) {
    throw new Error(`Set Color scheme not found`)
  }

  return colorScheme
}

export const ColorSchemeProvider = (props: {
  children: ReactNode
  defaultScheme?: ColorScheme
}) => {
  const [theme, setColorScheme] = React.useState(
    props.defaultScheme || ColorScheme.Light,
  )

  return (
    <SetColorSchemeContext.Provider value={setColorScheme}>
      <ColorSchemeContext.Provider value={theme}>
        {props.children}
      </ColorSchemeContext.Provider>
    </SetColorSchemeContext.Provider>
  )
}

export const StyledThemeProvider = (props: {
  themeConfig: ThemeConfig
  children: React.ReactNode
}) => {
  const theme = useColorScheme()

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_StyledColorSchemeProvider theme={props.themeConfig[theme]}>
      {props.children}
    </_StyledColorSchemeProvider>
  )
}
