import { configureTypeReduxApiCreator } from 'for-util/lib/redux/frr/rest'
import {
  createEmptyViewAction,
  createViewAction,
} from 'for-util/lib/redux/view.helpers'
import { API, Endpoints, mapEndpointToMethod } from '../../generated/auth.spec'
import { AppUser } from '../../generated/database.types'

const { createEndpoint } = configureTypeReduxApiCreator<
  API,
  Endpoints,
  typeof mapEndpointToMethod
>(mapEndpointToMethod)

export const me = createEndpoint()(
  {
    request: 'Auth/GET_ME_REQUEST',
    success: 'Auth/GET_ME_SUCCESS',
    failure: 'Auth/GET_ME_FAILURE',
  } as const,
  Endpoints.Me,
  {
    server: `${process.env.REACT_APP_APP_SERVER}/auth`,
  },
)

export const login = createEndpoint()(
  {
    request: 'Auth/LOGIN_REQUEST',
    success: 'Auth/LOGIN_SUCCESS',
    failure: 'Auth/LOGIN_FAILURE',
  } as const,
  Endpoints.Login,
  {
    server: `${process.env.REACT_APP_APP_SERVER}/auth`,
  },
)

export const register = createEndpoint<{
  navigate: any
}>()(
  {
    request: 'REGISTER_REQUEST',
    success: 'REGISTER_SUCCESS',
    failure: 'REGISTER_FAILURE',
  } as const,
  Endpoints.Register,
  {
    server: `${process.env.REACT_APP_APP_SERVER}/auth`,
  },
)

export enum ViewActionType {
  SetAuthUser = 'Auth/SetAuthUser',
  Logout = 'Auth/Logout',
  NoToken = 'Auth/NoToken',
}

export type SetAuthUser = {
  type: ViewActionType.SetAuthUser
  payload: AppUser
}

export const setAuthUser = createViewAction<SetAuthUser>(
  ViewActionType.SetAuthUser,
)

export type Logout = {
  type: ViewActionType.Logout
}

export const logout = createEmptyViewAction<Logout>(ViewActionType.Logout)

export type NoToken = {
  type: ViewActionType.NoToken
}

export const noToken = createEmptyViewAction<NoToken>(ViewActionType.NoToken)
