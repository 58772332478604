import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { store } from 'redux/store'
import { ErrorBoundary } from './ErrorBoundary'
import { Authenticator } from './scaffold/Authenticator'

export const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store as any}>
        <HashRouter>
          <Authenticator />
        </HashRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      </Provider>
    </ErrorBoundary>
  )
}
