import { ColorScheme, ThemeConfig } from 'for-web/stylesheet/theme'

export const fontSizes = {
  heading1: 35,
  heading2: 24,
  heading3: 18,
  heading4: 14,
  heading5: 12,
  heading6: 11,
  subtitle1: 12,
  subtitle2: 10,
  body1: 14,
  body2: 12,
  caption1: 14,
  caption2: 8,
}

export const sharedThemeConfig: ThemeConfig = {
  [ColorScheme.Light]: {
    colors: {
      brand: 'rgb(248,214,172)',
      brandDark: '#c79250',
      primaryText: 'white',
      primaryButtonColor: 'black',
      background: 'rgb(18,19,21)',
      cardBackground: 'rgb(36,38,42)',
    },
    fontSizes,
    boxShadow: '1px 1px 4px 1px #cdcbcb40',
    border: '1px solid #E2E7EC',
    fontFamily: 'Inter',
  },
  [ColorScheme.Dark]: {
    colors: {
      brand: 'rgb(248,214,172)',
      brandDark: '#c79250',
      primaryText: 'white',
      primaryButtonColor: 'black',
      background: 'rgb(18,19,21)',
      cardBackground: 'rgb(36,38,42)',
    },
    fontSizes,
    boxShadow: '1px 1px 8px 1px rgba(255,255,255,0.04)',
    border: '1px solid rgba(255,255,255,0.06)',
    fontFamily: 'Inter',
  },
}
