import { Select } from '../components/select/Select'
import { TextInput } from '../components/textInput/TextInput'
import { FieldLabel } from './FieldLabel'
import { FormFieldType, SingleFormField } from './types'
import { NumberInput } from 'for-web/components/textInput/NumberInput'
import { CheckOption } from 'for-web/components/select/CheckOption'
import { TextArea } from 'for-web/components/textInput/TextArea'
import { SelectIcon } from 'for-web/components/select/SelectIcon'
import { Button } from 'for-web/components'
import { StaticText } from 'for-web/components/textInput/StaticText'
import { NumberInputIncrement } from 'for-web/components/textInput/NumberInputIncrement'
import { ButtonType } from 'for-web/components/button/Button'

// import { CheckboxGroup } from '../../components/CheckboxGroup'
// import { Dropdown } from '../../components/Dropdown'
// import { DropdownNumber } from '../../components/DropdownNumber'
// import { InputWithDropdown } from '../../components/InputWithDropdown'
// import { CountryDropdown } from '../../components/CountryDropdown'

/*
 * Render field function
 */
type FieldItemProps<FormData> = {
  data: FormData
  //   errorLabel: string | string[]
  field: SingleFormField<FormData>
  fieldIndex: number
  //   hasError: boolean
  //   hasFocus?: boolean
  onChange: any

  //   onBlur: (value: any) => void
}

export const Field = <FormData extends {}>({
  data,
  //   errorLabel,
  field,
  fieldIndex,
  //   hasError,
  //   hasFocus,
  onChange,
}: //   onBlur,
FieldItemProps<FormData>) => {
  let { label } = field

  //   if (label) {
  //     label = {
  //       error: errorLabel !== null,
  //       errorLabel,
  //       errorDataTestId: `${field.lens.id()}.error`,
  //       ...label,
  //     }
  //   }

  const renderField = () => {
    switch (field.type) {
      case FormFieldType.TextInput: {
        const { type, lens, validate, required, ...fieldProps } = field
        return (
          <TextInput
            onlyOnBlur
            {...fieldProps}
            key={
              typeof fieldIndex === 'string'
                ? fieldIndex
                : `field-${fieldIndex}`
            }
            value={lens.get(data)}
            onBlur={(v) => onChange(field.lens, v)}
            onChange={(v) => onChange(field.lens, v)}
          />
        )
      }

      case FormFieldType.TextArea: {
        const { type, lens, validate, required, ...fieldProps } = field
        return (
          <TextArea
            {...fieldProps}
            key={
              typeof fieldIndex === 'string'
                ? fieldIndex
                : `field-${fieldIndex}`
            }
            value={lens.get(data)}
            onBlur={(v) => onChange(field.lens, v)}
            // onChange={(v) => onChange(field.lens, v)}
          />
        )
      }

      case FormFieldType.Select: {
        const { type, lens, validate, required, ...fieldProps } = field
        return (
          <Select
            {...fieldProps}
            required={required}
            value={lens.get(data)}
            onChange={(v) => onChange(field.lens, v)}
          />
        )
      }

      case FormFieldType.SelectIcon: {
        const { type, lens, validate, required, ...fieldProps } = field
        return (
          <SelectIcon
            {...fieldProps}
            required={required}
            value={lens.get(data)}
            onChange={(v) => onChange(field.lens, v)}
          />
        )
      }

      case FormFieldType.CheckOption: {
        const { type, lens, validate, required, ...fieldProps } = field
        return (
          <CheckOption
            {...fieldProps}
            value={lens.get(data)}
            onChange={(v) => onChange(field.lens, v)}
          />
        )
      }

      case FormFieldType.NumberInput: {
        const { type, lens, validate, required, ...fieldProps } = field
        return (
          <NumberInput
            onlyOnBlur
            {...fieldProps}
            value={lens.get(data)}
            onChange={(v) => onChange(field.lens, v)}
          />
        )
      }

      case FormFieldType.NumberInputIncrement: {
        const { type, lens, validate, required, ...fieldProps } = field
        return (
          <NumberInputIncrement
            onlyOnBlur
            {...fieldProps}
            value={lens.get(data)}
            onChange={(v) => onChange(field.lens, v)}
          />
        )
      }

      case FormFieldType.Button: {
        const { type, validate, required, buttonLabel, ...fieldProps } = field
        return (
          <Button
            {...fieldProps}
            type={ButtonType.Primary}
            label={buttonLabel}
            alternativeStyleKey="formButton"
          />
        )
      }

      case FormFieldType.Text: {
        const { type, lens, validate, required, ...fieldProps } = field
        return (
          <StaticText
            {...fieldProps}
            key={
              typeof fieldIndex === 'string'
                ? fieldIndex
                : `field-${fieldIndex}`
            }
            value={lens.get(data)}
            // onChange={(v) => onChange(field.lens, v)}
          />
        )
      }

      default:
        return <></>
    }
  }

  return (
    <>
      {label && <FieldLabel {...label} />}
      {renderField()}
    </>
  )
}
