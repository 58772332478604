import { Props as TextInputProps, TextInput } from './TextInput'

export type Props = Omit<TextInputProps, 'onChange' | 'value' | 'onBlur'> & {
  onChange: (value: number) => void
  value: number | null | undefined
  onBlur?: (value: number | null) => void
}

export const NumberInput = (props: Props) => {
  const { value } = props
  return (
    <TextInput
      {...props}
      value={value === null || value === undefined ? '0' : `${props.value}`}
      onChange={(value) => {
        props.onChange(value === '' ? 0 : Number(value))
      }}
      onBlur={(value) => {
        if (props.onBlur) {
          props.onBlur(value === '' ? 0 : Number(value))
        } else if (props.onChange) {
          props.onChange(value === '' ? 0 : Number(value))
        }
      }}
      htmlInputProps={{
        ...props.htmlInputProps,
        type: 'number',
      }}
    />
  )
}
