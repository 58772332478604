import { Props as TextInputProps } from '../components/textInput/TextInput'
import { Props as TextAreaProps } from '../components/textInput/TextArea'
import { Props as SelectProps } from '../components/select/Select'
import { Props as SelectIconProps } from '../components/select/SelectIcon'
import { Props as CheckOptionProps } from '../components/select/CheckOption'
import { Props as NumberInputProps } from '../components/textInput/NumberInput'
import { Props as NumberInputSpecialProps } from '../components/textInput/NumberInputIncrement'
import { Props as ButtonProps } from '../components/button/Button'
import { Props as StaticTextProps } from '../components/textInput/StaticText'

import { ReactNode } from 'react'
import { FormLens } from './util'
import { Stylesheet } from '../stylesheet/stylesheet'
import { LabelProps } from './FieldLabel'

export enum FormFieldType {
  FormFieldGroup = 'FormFieldGroup',
  FormSection = 'FormSection',

  Text = 'Text',

  // Text Input
  TextInput = 'TextInput',
  TextArea = 'TextArea',

  // Number Input
  NumberInput = 'NumberInput',
  NumberInputIncrement = 'NumberInputIncrement',

  // Select Element
  Select = 'Select',
  SelectIcon = 'SelectIcon',
  CheckOption = 'CheckOption',

  // Static
  Button = 'Button',
}

export enum Orientation {
  Row = 'Row',
  Column = 'Column',
}

export enum DisplayType {
  Edit = 'Edit',
  View = 'View',
}

export type DropdownOption = {
  label: string
  value: string
}

type StaticFormInput<P, T> = Omit<P, 'type' | 'label'> & {
  type: T
}

type FormInput<V, P extends { value: V }, L, T> = Omit<
  P,
  'onChange' | 'onBlur' | 'value' | 'error' | 'required' | 'type'
> & {
  lens: L
  type: T
  readOnly?: boolean
  readOnlyMapper?: (
    params: Omit<P, 'onChange' | 'onBlur'>,
  ) => string | ReactNode
  readOnlyOptions?: {
    isHighlighted?: boolean
    image?: string
  }
  _value?: P['value']
}

// type FormSelect<L, P> = Omit<P, 'onChange' | 'onBlur' | 'value' | 'error' | 'required'> & {
//   type: FormFieldType.Select
//   lens: L
// }

type NullableAndUndefinabled<T> = T | null | undefined
type NullableAndUndefinabledLens<FormData, T> =
  | FormLens<FormData, T>
  | FormLens<FormData, T | null>
  | FormLens<FormData, T | undefined>
  | FormLens<FormData, T | null | undefined>

export type TextInputField<FormData> = FormInput<
  NullableAndUndefinabled<string>,
  TextInputProps,
  NullableAndUndefinabledLens<FormData, string>,
  FormFieldType.TextInput
>

export type TextAreaField<FormData> = FormInput<
  NullableAndUndefinabled<string>,
  TextAreaProps,
  NullableAndUndefinabledLens<FormData, string>,
  FormFieldType.TextArea
>

export type SelectField<FormData> = FormInput<
  NullableAndUndefinabled<string>,
  SelectProps,
  NullableAndUndefinabledLens<FormData, string>,
  FormFieldType.Select
>

export type SelectIconField<FormData> = FormInput<
  NullableAndUndefinabled<string>,
  SelectIconProps,
  NullableAndUndefinabledLens<FormData, string>,
  FormFieldType.SelectIcon
>

export type CheckOptionField<FormData> = FormInput<
  NullableAndUndefinabled<boolean>,
  CheckOptionProps,
  NullableAndUndefinabledLens<FormData, boolean>,
  FormFieldType.CheckOption
>

export type NumberInputField<FormData> = FormInput<
  NullableAndUndefinabled<number>,
  NumberInputProps,
  NullableAndUndefinabledLens<FormData, number>,
  FormFieldType.NumberInput
>

export type NumberInputSpecialField<FormData> = FormInput<
  NullableAndUndefinabled<number>,
  NumberInputSpecialProps,
  NullableAndUndefinabledLens<FormData, number>,
  FormFieldType.NumberInputIncrement
>

export type ButtonField = StaticFormInput<
  ButtonProps & { buttonLabel: string },
  FormFieldType.Button
>

export type TextField<FormData> = FormInput<
  NullableAndUndefinabled<string>,
  StaticTextProps,
  NullableAndUndefinabledLens<FormData, string>,
  FormFieldType.Text
>

type CommonFieldProps<FormData> = {
  isVisible?: (formData: FormData) => boolean
  isDisabled?: boolean
  style?: Partial<Stylesheet['formRow']>
  validate?: (value: any) => null | string
  label?: LabelProps
  maxWidth?: number
  required?: boolean | ((formData: FormData) => boolean)
  renderChildren?: () => ReactNode
}

// @ts-ignore
// export const fieldMap = {
//     [FormFieldType.FormFieldGroup]: null,
//     [FormFieldType.FormFieldRepeatGroup]: null,
//     [FormFieldType.FormFieldRepeatSection]: null,
//     [FormFieldType.FormSection]: null,
//
//     [FormFieldType.TextInput]: null as TextInputField<unknown>,
//     [FormFieldType.Select]: null as SelectField<unknown>,
// } as any

export type SingleFormField<FormData> = (
  | TextInputField<FormData>
  | TextAreaField<FormData>
  | SelectField<FormData>
  | SelectIconField<FormData>
  | CheckOptionField<FormData>
  | NumberInputField<FormData>
  | NumberInputSpecialField<FormData>
  | ButtonField
  | TextField<FormData>
) &
  CommonFieldProps<FormData>

// export type MultiInputField<FormData> = {
//   label?: LabelProps
//   type: FormFieldType.MultiInput
//   fields: Array<SingleFormField<FormData>>
//   style?: Partial<Stylesheet['formRow']>
//   isVisible?: (formData: FormData) => boolean
// }

export type FormFieldRow<FormData> = Array<SingleFormField<FormData>>

// export type Fields<FormData> = Array<
//   SingleFormField<FormData> | FormFieldRow<FormData>
// >

export type SingleFieldOrRow<FormData> =
  | SingleFormField<FormData>
  | FormFieldRow<FormData>

export type GroupField<FormData> = SingleFieldOrRow<FormData>

export enum IconActionType {
  Popover,
  OnClick,
}

export type FormFieldGroup<FormData> = {
  description?: string

  isVisible?: (formData: FormData) => boolean
  style?: Partial<Stylesheet['formGroup']>
  title?: string
  type: FormFieldType.FormFieldGroup
  iconActions?: Array<
    | {
        type: IconActionType.OnClick
        show: boolean
        icon: ReactNode
        onClick: () => void
      }
    | { type: IconActionType.Popover; show: boolean; popOver: ReactNode }
  >
} & (
  | {
      render: (group: FormFieldGroup<FormData>) => ReactNode
    }
  | {
      fields: Array<GroupField<FormData>>
    }
)

export type InternalSectionField<FormData> =
  | SingleFormField<FormData>
  | FormFieldRow<FormData>
  | FormFieldGroup<FormData>

export type SectionField<FormData> = InternalSectionField<FormData>

export type SectionFields<FormData> = Array<SectionField<FormData>>

export type InternalSectionFields<FormData> = Array<
  InternalSectionField<FormData>
>

export type FormSection<FormData> = {
  dataTestId?: string
  description?: string
  fieldComponent?: ReactNode
  fields: SectionFields<FormData>
  introduction?: string
  introductionReadOnly?: string
  isVisible?: (formData: FormData) => boolean
  editLabel?: string
  onEdit?: () => void
  style?: Partial<Stylesheet['formSection']>
  title?: string
  titleData?: any
  type: FormFieldType.FormSection
  TitleCenterComponent?: ReactNode
}

export type InternalFormField<FormData> =
  | SingleFormField<FormData>
  | FormFieldRow<FormData>
  | FormFieldGroup<FormData>
  | FormSection<FormData>

export type FormField<FormData> = InternalFormField<FormData>

export type FormFields<FormData> = Array<FormField<FormData>>

export type CommonThreadProps<FormData> = {
  data: FormData
  errorFieldId?: string | null
  fieldIndex: number
  onChange: (lens: FormLens<FormData, any>, value: any) => void
  showValidation: boolean
}

export type FieldError = {
  error: string | null
  fieldId: string
}
