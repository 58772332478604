import { Stylesheet, useStylesheet } from 'for-web/stylesheet/stylesheet'
import { useCSSStyles } from 'for-web/stylesheet/util'
import { useEffect, useState } from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import styled from 'styled-components'

export const Checkbox = (props: {
  isActive: boolean
  style?: Partial<Stylesheet['checkbox']>
  hover?: boolean
}) => {
  const stylesheet = useStylesheet()
  const getCSSStyle = useCSSStyles(stylesheet, 'checkbox')(props.style)
  const [hover, setHover] = useState(props.hover || false)

  useEffect(() => {
    setHover(props.hover || false)
  }, [props.hover])
  return (
    <Outer
      hover={hover}
      active={props.isActive}
      outerstyle={getCSSStyle(['outer']).cssStyles}
      hoverstyle={getCSSStyle(['outerHover']).cssStyles}
      activestyle={getCSSStyle(['outerActive']).cssStyles}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => (props.hover ? {} : setHover(false))}
    >
      {props.isActive && (
        <IoMdCheckmark style={getCSSStyle(['icon']).computedStyle} />
      )}
    </Outer>
  )
}

const Outer = styled.div<{
  hover: boolean
  active: boolean
  outerstyle: string
  hoverstyle: string
  activestyle: string
}>`
  ${({ outerstyle }) => outerstyle}
  ${({ hover, hoverstyle }) => (hover ? hoverstyle : '')}
  ${({ active, activestyle }) => (active ? activestyle : '')}
`
