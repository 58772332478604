import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Button, Props as ButtonProps } from '../components/button/Button'
import { useCSSStyles } from '../stylesheet/util'
import { FormLens, setScrolled } from './util'
import { FieldGroup } from './FieldGroup'
import { FieldRow } from './FieldRow'
import { FieldSection } from './FieldSection'
import { filterByHidden, filterByVisible } from './functions/filter.form'
import { flatten } from './functions/flatten'
import { mapFormFields } from './functions/map.form'
import { computeFieldError } from './hooks/useFormFieldError'
import {
  DisplayType,
  FieldError,
  FormField,
  FormFieldType,
  InternalFormField,
  SingleFormField,
} from './types'
import { Stylesheet, useStylesheet } from '../stylesheet/stylesheet'
import { Div } from '../html'

type OnInvalidSubmitType<FormData> = (params: {
  errors: Array<FieldError>
  formState: FormData
}) => void

export type FormAnalytics<FormData> = {
  onSubmit?: () => void
  onInvalidSubmit?: OnInvalidSubmitType<FormData>
}

export type FormProps<FormData> = {
  data: FormData
  formFields: Array<FormField<FormData>>
  buttons?: Array<
    Omit<ButtonProps, 'onClick'> & {
      onClick: (params: { submit: () => void }) => void
      isDisabled?: (d: FormData) => boolean
    }
  >
  children?: ReactNode
  className?: string
  disableValidation?: boolean
  display?: DisplayType
  isVisible?: (formData: FormData) => boolean
  onChange?: (formState: FormData) => void
  onInvalidSubmit?: OnInvalidSubmitType<FormData>
  onSubmit?: (params: { formState: FormData }) => void
  readOnly?: boolean
  style?: Partial<Stylesheet['formWrapper']>
}

export const Form = <FormData extends {}>({
  buttons,
  data,
  children,
  disableValidation,
  formFields,
  isVisible,
  onChange,
  onInvalidSubmit,
  onSubmit,
  readOnly,
  style,
}: FormProps<FormData>) => {
  const stylesheet = useStylesheet()
  const getFormStyle = useCSSStyles(stylesheet, 'formWrapper')(style)

  const [showValidation, setShowValidation] = React.useState(false)

  const hiddenFormFields = flatten(filterByHidden({ data, formFields }), data)
  const visibleFormFields = filterByVisible({ data, formFields })

  const internalOnChange = useCallback(
    (lens: FormLens<FormData, any>, value: any) => {
      if (onChange) {
        onChange(lens.set(value)(data))
      }
    },
    [data, onChange],
  )

  useEffect(() => {
    hiddenFormFields.forEach((f) => {
      const v = 'lens' in f ? f.lens.get(data) : null
      if (v !== null && 'lens' in f) {
        internalOnChange(f.lens, null)
      }
    })
  }, [data, hiddenFormFields, internalOnChange])

  useEffect(() => {
    setShowValidation(false)
    setScrolled(false)
  }, [formFields])

  const getFieldError = (
    field: SingleFormField<FormData>,
  ): { error: string | null; fieldId: string } => {
    const value = 'lens' in field ? (field.lens.get(data) as any) : null
    return computeFieldError({ value, data, field })
  }

  const [errorFieldId, setErrorFieldId] = useState<string | null>(null)

  const submit = () => {
    setErrorFieldId(null)
    if (disableValidation && onSubmit) {
      onSubmit({ formState: data })
    } else {
      const errors = mapFormFields(visibleFormFields, getFieldError).filter(
        (fieldError) => !!fieldError.error,
      )

      if (errors.length > 0) {
        setErrorFieldId(errors[0].fieldId)
        setShowValidation(true)
        onInvalidSubmit?.({ errors, formState: data })
      } else {
        onSubmit?.({ formState: data })
      }
    }
  }

  const commonFieldProps = {
    data,
    errorFieldId,
    formReadOnly: readOnly,
    onChange: internalOnChange,
    showValidation,
    style,
  }

  const renderField = (
    field: InternalFormField<FormData>,
    fieldIndex: number,
  ) => {
    if (Array.isArray(field)) {
      return (
        <FieldRow
          key={`field-form-${fieldIndex}`}
          fieldIndex={0}
          field={field}
          {...commonFieldProps}
        />
      )
    }

    switch (field.type) {
      case FormFieldType.FormFieldGroup: {
        return (
          <FieldGroup
            key={`field-group-${fieldIndex}`}
            field={field}
            fieldIndex={fieldIndex}
            {...commonFieldProps}
          />
        )
      }

      case FormFieldType.FormSection:
        return (
          <FieldSection
            key={`field-${fieldIndex}`}
            field={field}
            fieldIndex={fieldIndex}
            {...commonFieldProps}
          />
        )

      default:
        return (
          <FieldRow
            key={`field-${fieldIndex}`}
            field={[field]}
            fieldIndex={fieldIndex}
            {...commonFieldProps}
          />
        )
    }
  }

  return !isVisible || isVisible(data) ? (
    <Div {...getFormStyle('wrapper')} readOnly={readOnly}>
      <Div {...getFormStyle('content')}>
        {/* formFields.map(renderField) */}
        {visibleFormFields.map(renderField)}
      </Div>
      {buttons && (
        <Div {...getFormStyle('buttonContainer')} data-test-id="form-actions">
          {buttons.map((button, k) => (
            <Button
              {...button}
              key={k}
              disabled={
                button.isDisabled ? button.isDisabled(data) : !!button.disabled
              }
              onClick={() => button.onClick({ submit })}
            />
          ))}
        </Div>
      )}
      {children}
    </Div>
  ) : (
    <></>
  )
}
