import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import { RootSaga } from './sagas/root.saga'

import { combineReducers } from 'redux'
import { dataReducer, DataState } from './reducers/data.reducer'
import { authReducer, AuthState } from './reducers/auth.reducer'

export type ReduxState = {
  data: DataState
  auth: AuthState
  router: any
}

export const createRootReducer = (history: any) =>
  combineReducers({
    data: dataReducer,
    auth: authReducer,
  } as any)

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

export const configureStore = (initialState = {}) => {
  return createStore(
    createRootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(thunkMiddleware, sagaMiddleware)),
  )
}

export const store = configureStore()

sagaMiddleware.run(RootSaga)
