import { Div } from '../html'
import { useStylesheet } from '../stylesheet/stylesheet'
import { useCSSStyles } from '../stylesheet/util'
import { FieldRowItem } from './FieldRowItem'
import { CommonThreadProps, FormFieldRow } from './types'

type FieldRowProps<FormData> = CommonThreadProps<FormData> & {
  field: FormFieldRow<FormData>
}

// ------------------------------------
export const FieldRow = <FormData extends {}>({
  data,
  errorFieldId,
  field,
  fieldIndex,
  onChange,
  showValidation,
}: FieldRowProps<FormData>) => {
  // Form styles
  const stylesheet = useStylesheet()
  const getRowStyle = useCSSStyles(stylesheet, 'formRow')()
  return (
    <Div key={`row-${fieldIndex}`} {...getRowStyle('wrapper')}>
      {field.map((fieldItem, fieldItemIndex) => (
        <FieldRowItem
          data={data}
          errorFieldId={errorFieldId}
          field={fieldItem}
          fieldIndex={fieldItemIndex}
          key={`field-item-${fieldItemIndex}`}
          onChange={onChange}
          showValidation={showValidation}
        />
      ))}
    </Div>
  )
}
