import { P } from '../html'
import { Div } from '../html'
import { useStylesheet } from '../stylesheet/stylesheet'
import { useCSSStyles } from '../stylesheet/util'
import { FieldGroup } from './FieldGroup'

import { FieldRow } from './FieldRow'

import {
  CommonThreadProps,
  FormFieldType,
  FormSection,
  InternalSectionField,
} from './types'

type FieldSection<FormData> = CommonThreadProps<FormData> & {
  field: FormSection<FormData>
  onFormEdit?: () => void
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FieldSection = <FormData extends {}>({
  data,
  errorFieldId,
  field: fieldSection,
  fieldIndex: fieldSectionIndex,
  onChange,
  onFormEdit,
  showValidation,
}: // eslint-disable-next-line @typescript-eslint/no-unused-vars
FieldSection<FormData>) => {
  // Form styles
  const stylesheet = useStylesheet()
  const getSectionStyle = useCSSStyles(
    stylesheet,
    'formSection',
  )(fieldSection.style)
  //const getSectionRightStyle = useCSSStyles(stylesheet, 'formSectionRight')({})

  const commonFieldProps = {
    data,
    errorFieldId,
    onChange,
    showValidation,
  }

  const renderSectionField = (
    field: InternalSectionField<FormData>,
    fieldIndex: number,
  ) => {
    if (Array.isArray(field)) {
      return (
        <FieldRow
          key={`field-section-${fieldIndex}`}
          field={field}
          fieldIndex={fieldIndex}
          {...commonFieldProps}
        />
      )
    }

    switch (field.type) {
      case FormFieldType.FormFieldGroup: {
        return (
          <FieldGroup
            key={`field-group-${fieldIndex}`}
            field={field}
            fieldIndex={fieldIndex}
            {...commonFieldProps}
          />
        )
      }

      default:
        return (
          <FieldRow
            key={`field-${fieldIndex}`}
            field={[field]}
            fieldIndex={fieldIndex}
            {...commonFieldProps}
          />
        )
    }
  }

  // Render
  return (
    <Div
      key={
        typeof fieldSectionIndex === 'string'
          ? fieldSectionIndex
          : `section-${fieldSectionIndex}`
      }
      data-test-id={fieldSection.dataTestId}
      {...getSectionStyle('wrapper', fieldSection.style?.wrapper || {})}
    >
      <Div {...getSectionStyle('contentWrapper')}>
        <Div {...getSectionStyle('content')}>
          {fieldSection.title
            ? (fieldSection.TitleCenterComponent && (
                <Div {...getSectionStyle('titleWrapper')}>
                  <P
                    {...getSectionStyle(
                      'title',
                      fieldSection.style?.title || {},
                    )}
                    data={fieldSection.titleData}
                  >
                    {fieldSection.title}
                  </P>

                  {fieldSection.TitleCenterComponent}
                </Div>
              )) || (
                <P
                  {...getSectionStyle('title', fieldSection.style?.title || {})}
                  data={fieldSection.titleData}
                >
                  {fieldSection.title}
                </P>
              )
            : null}

          {fieldSection.description && (
            <P
              {...getSectionStyle('description')}
              label={fieldSection.description}
            />
          )}

          {fieldSection.fields.map(renderSectionField as any)}
        </Div>
      </Div>
    </Div>
  )
}
